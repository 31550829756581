import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { assetsCategory, contents, catAssets, seriesAssest, partnerAssets } from 'src/app/shared/constants';
import { HttpParams, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { UikitService } from './uikit.service';
import { Router } from '@angular/router';
import { environment } from 'src/environment';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {

  categories = new BehaviorSubject([]);
  channelsList;
  bannerData: any;
  contentData;
  fetchCategoryData = false;
  constructor(private http: HttpClient, private uikitService: UikitService,
    private router: Router) { }
// this service call using to for response categories for list
  getCategories(): Observable<any> {
    const homeURL = environment.apiEndpoint + environment.version + assetsCategory;
    const params = new HttpParams()
      .set('version', 'v1');
    return this.http.get(homeURL, { params }).pipe(
      map(
        (response: any) => {
          if (response.status_code === 200) {
            const respData = response.data;
            this.categories.next(respData.categories);
            if (respData.livechannels) {
              this.channelsList = respData.livechannels;
            }
            return response.data;
          }
          else {
            this.uikitService.notifyError(response);
          }
        }), catchError((error) => {
          if (error?.error?.error?.code !== 1002) {
            this.uikitService.notifyError(error);
          }
          return error;
        })
    );
  }

  getCategoryContents(catTitle, queryUniqueId) {
    let catgoryURL;
    if(queryUniqueId) catgoryURL = environment.apiEndpoint + environment.v3_version + partnerAssets + queryUniqueId + contents;
    else catgoryURL = environment.apiEndpoint + environment.v3_version + catAssets + catTitle + contents;
    const params = new HttpParams()
      .set('version','v1');
    return this.http.get(catgoryURL, { params })
      .pipe(
        map(
          (response: any) => {
            if (response.status_code === 200) {
              const respData = response.data;
              this.contentData = response.data;
              return respData;
            }
            else {
              this.uikitService.notifyError(response);
              this.router.navigateByUrl('/404');
            }
          }
          , (error: HttpErrorResponse) => {
            this.uikitService.notifyError(error);
            this.router.navigateByUrl('/404');
          }
        )
      );
  }

  getSeriesContents(id) {
    const catgoryURL = environment.apiEndpoint + environment.version + seriesAssest + id;
    const params = new HttpParams()
      .set('version','v1');
    return this.http.get(catgoryURL, { params })
      .pipe(
        map(
          (response: any) => {
            if (response.status_code === 200) {
              const respData = response.data;
              return respData;
            }
            else {
              this.uikitService.notifyError(response);
              this.router.navigateByUrl('404');
            }
          }), catchError((error) => {
            if (error?.error?.error?.code !== 1002) {
              this.uikitService.notifyError(error);
              this.router.navigateByUrl('404');
            }
            return error;
          })
      );
  }
// this service call using to for response categories for list
  getNextVersionCategories(): Observable<any> {
    const homeURL = environment.apiEndpoint + environment.v3_version + assetsCategory;
    const params = new HttpParams()
      .set('version', 'v1');
    return this.http.get(homeURL, { params }).pipe(
      map(
        (response: any) => {
          if (response.status_code === 200) {
            const respData = response.data;
            this.categories.next(respData);
            this.fetchCategoryData = true;
            // if (respData.livechannels) {
            //   this.channelsList = respData.livechannels;
            // }
            return response.data;
          }
          else {
            this.uikitService.notifyError(response);
          }
        }), catchError((error) => {
          if (error?.error?.error?.code !== 1002) {
            // this.uikitService.notifyError(error);
          }
          return error;
        })
    );
  }
}
