<div [class]="skinName">
  @if (!guest && showUpdateCard) {
    <div class="update-card-head navbar-header header_section">
      <div class="info">
        <img src="branding/theme/assets/static_assets/ic-exclaimation-email.svg">
        <p>
          {{ configService.localeKeys?.header_confirm_payment_info }}
        </p>
      </div>
      <div>
        <button [routerLink]="['/payment/update']" [queryParams]="{'session_id':sessionId}" class="update-card">
          {{ configService.localeKeys?.['account.update_payment.text'] }}
        </button>
      </div>
    </div>
  }

</div>