<div id="pagetitle">
  <h1 class="wdn-text-hidden">{{pageH1Tag}}</h1>
</div>
<app-confirm-card></app-confirm-card>
<div class="bodyParent" [ngClass]="{'show-card': showUpdateCard}" [hidden]="loading">
  <app-header [hidden]="!settingsService.showHeader"></app-header>
  <router-outlet></router-outlet>
  @if (showHoverCardParentDiv){
    <div #hoverkey class="hover-card" [ngClass]="{'hover-scaling':(scaling == 'scale' && orientation == 1), 'hover-scaling-origin-first':(scaling == 'scale' && (firstCard == 1)) , 'hover-scaling-origin':(scaling == 'scale' && (lastCard == 1)), 'hover-scaling-land':(scaling == 'scale' && orientation != 1), 'hover-scaling-back': scaling == 'none'}" (mouseleave)="onMouseLeave()">
      @if (showHoverData){
        <div>
          @if (key !== 'livechannel_widget' && key !== 'group_collection_widget' &&  key !== 'top_in_region_widget' && key !== 'content_partner_widget'){
            <div class="slider-card" [ngClass]="{'visibility':imageLoadedComplete, 'visibilityHidden':!imageLoadedComplete}" >
              <div class="card-img-container" (click)="redirect(value,key,dataTitle)">
                @if (!showTitle){
                  <img [src]="cardIMg" (error)="imageLoaded()" (load)="imageLoaded()" [ngClass]="{'card-img':orientation == 1,'card-img-landscape':(orientation == 2 || orientation == 3)}" />
                }
                @if (showTitle){
                  <div class="no-image" [ngClass]="{'card-img':orientation == 1,'card-img-landscape':(orientation == 2 || orientation == 3)}">
                    <a class="txt-trunc card-title">{{value?.title || value?.title_en}} </a>
                 </div>
                }
                @if ((!contentPartner && value?.partnerLogo && settingsService.enableModerators)) {
                  <span class="partner-logo">
                    <img loading="lazy" src="{{imagePath+value?.partnerLogo}}" alt="" />
                  </span>
                }
                  <div class="img-gradient" [ngClass]="{'img-gradient-none':scaling == 'none'}" ></div>
              </div>
              <div class="onhover-card" [ngClass]="{'onhover-card-cont':key == 'continue_watching_widget' , 'onhover-card-non-cont':key != 'continue_watching_widget'}">
                  <div class="meta-card" [ngStyle]="{'display': displayOnTitle ? 'grid': 'flex'}">
                    @if (displayOnTitle){
                      <p class="meta-title">{{value?.title}}</p>
                    }
                      <p [ngClass]="{'cont-watch-meta-card': key == 'continue_watching_widget'}" class="meta-data-truncate"  >{{value?.metaCard}}</p>
                  </div>
              </div>
              @if (key == 'continue_watching_widget'){
                <mat-progress-bar mode="determinate" [value]=[value?.percentage]></mat-progress-bar>
              }
              <div [ngClass]="{'cont-watch-action-card': key == 'continue_watching_widget'}" class="actions-card">
                  <span class="actions-card-container">
                    @if (!guest){
                      <span class="action-card-tooltip">
                        <img (click)="CallingPlayContentService(value, 'play')" class="cursor-pointer card-icon" src="branding/theme/assets/static_assets/ic-play-icon-on-card.svg">
                        <span class="action-card-tooltip-text margin-null">{{ playText || 'Play'}}</span>
                      </span>
                    }
                    @if (!value?.wishlist && !guest && !value?.is_livechannel && !restrictedUser){
                      <span class="action-card-tooltip">
                        <img (click)="CallingPlayContentService(value, 'add')" class="cursor-pointer card-icon" src="branding/theme/assets/static_assets/ic-empty-heart-on-lbg.svg">
                        <span class="action-card-tooltip-text margin-null">{{ addWishListText || 'Add to wishlist'}}</span>
                      </span>
                    }
                    @if (value?.wishlist && !guest && !value?.is_livechannel && !restrictedUser){
                      <span class="action-card-tooltip">
                        <img (click)="CallingPlayContentService(value, 'remove')" class="cursor-pointer card-icon" src="branding/theme/assets/static_assets/ic-filled-heart-on-lbg.svg">
                        <span class="action-card-tooltip-text margin-null">{{ removeWishListText || 'Remove from watchlist'}}</span>
                      </span>
                    }
                      <span class="action-card-tooltip">
                          <img (click)="redirect(value,key,dataTitle)" class="cursor-pointer card-icon" src="branding/theme/assets/static_assets/ic-info-on-lbg.svg">
                          <span class="action-card-tooltip-text margin-null'">{{ seeInfoText || 'See info'}}</span>
                      </span>
                      @if (!(guest) && key == 'continue_watching_widget' && !restrictedUser){
                        <span class = "action-card-tooltip">
                          <img (click)="removeContinue(value?.continue_id)" [ngClass]="preventClick ? 'no-pointer' : ''" class="cursor-pointer card-icon" src="branding/theme/assets/static_assets/ic-cross-round-on-lbg.svg">
                          <span class = "action-card-tooltip-text margin-left">{{ removeListText || 'Remove from this list'}}</span>
                        </span>
                      }
                  </span>
                  @if (value?.is_series && !guest && key == 'continue_watching_widget'){
                    <span class="card-duration">
                      @if (value?.season_position){
                        <span>
                          {{seasonText}}{{value?.season_position}}&nbsp;-&nbsp;{{episodeText}}{{value?.position}}
                        </span>
                      }
                      @if (value?.season_position == 0){
                        <span>
                          {{episodeText}}{{value?.position}}
                        </span>
                      }
                    </span>
                  }
              </div>
          </div>
          }
      </div>
      }
  </div>
  }
  @if (footerLoad && settingsService.showFooter) {
    <app-footer></app-footer>
  }
</div>
@if(showAudioPlayer && audioSupport){
  <div class="audio-podcast">
    <app-audio-podcast [data]="audioDataObject"></app-audio-podcast>
  </div>
}
<div class="loader-align loader-center" [hidden]="!loading">
  <img class="loader-size" src="branding/theme/assets/static_assets/ic-app-loader.svg" alt="loader">
</div>