import { Injectable } from '@angular/core';
import { HttpParams, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { assetSeeall } from 'src/app/shared/constants';
import { ActivatedRoute, Router } from '@angular/router';
import { UikitService } from './uikit.service';
import { environment } from 'src/environment';
import { StorageService } from './storage.service';
import { SettingsService } from './settings.service';

@Injectable({
  providedIn: 'root'
})
export class SubCategoryService {

  constructor(private http: HttpClient, private router: Router,
              private uikitService: UikitService,private storageService: StorageService, private route:ActivatedRoute,  private settingService:SettingsService) { }
// this service call get subcategory details list
  getSubCategory(data): Observable<any> {
    let partnerSlug = this.route.snapshot.paramMap.get('partnerName');
    let partnerUniqueId;
    if(partnerSlug){
      let findPartner = this.settingService.contentPartners.find(obj => obj.slug == partnerSlug);
      partnerUniqueId = findPartner.unique_id;
    }
    const guest = data.guest;
    const cat = data.cat;
    const title = data.title;
    const skip = data.skip;
    const take = data.take;
    let paramurl;
    let subCatURL;
    let params: HttpParams = new HttpParams()
    if (guest) {
      if (cat) {
        const key = cat;
        const categoryId = title;
        paramurl = key + '/' + categoryId;
        params = params.append('key', key)
          .append('component', data?.component)
          .append('category', categoryId)
          .append('skip', skip)
          .append('take', take)
          .append('version','v1');
      } else {
        const key = title;
        paramurl = key;
        params = params.append('key', key)
        .append('component', data?.component)
          .append('skip', skip)
          .append('take', take)
          .append('version','v1');
      }
    } else {
      if (cat) {
        const key = cat;
        const categoryId = title;
        paramurl = key + '/' + categoryId;
        params = params.append('key', key)
          .append('component', data?.component)
          .append('category', categoryId)
          .append('skip', skip)
          .append('take', take)
          .append('version','v1');
      } else {
        const key = title;
        paramurl = key;
        params = params.append('key', key)
          .append('component', data?.component)
          .append('skip', skip)
          .append('take', take)
          .append('version','v1');
      }
    }
    if(this.route.snapshot.queryParams.partner_id || partnerSlug){
      if(partnerSlug){
        params = params.append("content_partner_id" , partnerUniqueId);
        subCatURL =  environment.apiEndpoint + environment.v3_version + assetSeeall + paramurl + '/' +  partnerUniqueId;
      }else{
        params = params.append("content_partner_id" , this.route.snapshot.queryParams.partner_id);
        subCatURL =  environment.apiEndpoint + environment.v3_version + assetSeeall + paramurl + '/' +  this.route.snapshot.queryParams.partner_id;
      }
    }else subCatURL = environment.apiEndpoint + environment.v3_version + assetSeeall + paramurl;
    return this.http.get(subCatURL, { params })
      .pipe(
        map(
          (response: any) => {
            if (response.status_code === 200) {
              const respData = response.data;
              return respData;
            }
            else {
              this.uikitService.notifyError(response);
              this.router.navigateByUrl('404');
            }
          }), catchError((error) => {
            if (error?.error?.error?.code !== 1002) {
              this.uikitService.notifyError(error);
              this.router.navigateByUrl('404');
            }
            return error;
          })
      );
  }
}
