import { Injectable } from '@angular/core';
import { environment } from 'src/environment';
import { SettingsService } from './settings.service';

@Injectable({
  providedIn: 'root'
})
export class DeepLinkService {

  constructor(private settingsService: SettingsService ) { }

  deeplink(mobileType) {
    if ('isIphone' === mobileType) {
     const iosAppHref = this.settingsService.iosAppHref;
     const app = {
       launchApp() {
        setTimeout(() => {
          window.location.href = iosAppHref;
        }, 25);
        window.location.href = iosAppHref;
       },
       openWebApp() {
        window.location.href = iosAppHref;
       }
   };
     app.launchApp();
  } else if ('isAndroid' === mobileType) {
     const androidAppHref = this.settingsService.androidAppHref;
     const app = {
       launchApp() {
        window.location.replace(androidAppHref);
        setTimeout(this.openWebApp, 500);
       },
       openWebApp() {
        window.location.href =  androidAppHref;
       }
   };
     app.launchApp();
  }else{
    const routeUrl = this.settingsService.routeUrl || environment.routeUrl;
    let utm_url = this.settingsService.getUTMurl(routeUrl);
    window.location.href = utm_url;
  }
 }
}
