import {  Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { last } from 'rxjs/operators';
import { BaseService } from './base.service';
import { ContentService } from './content.service';
import { DatalayerService } from './datalayer.service';
import { HomeService } from './home.service';
import { SettingsService } from './settings.service';
import { StorageService } from './storage.service';
import { WishlistService } from './wishlist.service';
import {environment}  from '../../../environment'
import npaw from 'npawlib';
import { youboraAdapaterCode } from 'src/app/shared/constants';

declare global {
  interface Window {
    plugin?: any;
  }
}

@Injectable({
  providedIn: 'root'
})
export class PlayContentService {
  categoryName: any;
  searchData: any;
  pageDetail: string;
  scrollTopValue: number;
  removeList = false;
  progressBarPercentage = new BehaviorSubject<number>(0);
  epgPlayContent = new BehaviorSubject<any>({});
  epgNextContent = new BehaviorSubject<string>('');
  playEnded = new BehaviorSubject<string>('');
  showAudioPlayer = new BehaviorSubject<boolean>(false);
  audioContentObject = new BehaviorSubject<object>({});
  currentContentCollection = new BehaviorSubject<object>({});
  selectedContentType = new BehaviorSubject<string>('audio');
  closeMiniPlayer = new BehaviorSubject<boolean>(false);
  channelNotAvailable = false;
  firstInitInSmartChannel = false;
  smartChannelData;
  ChannelWithAD = false;

  constructor(private router: Router, private contentService: ContentService,
              private storageService: StorageService, private datalayerService: DatalayerService,
              private settingsService: SettingsService, private wishListService: WishlistService,
              private homeService: HomeService, private baseService: BaseService) { }

  setYouboraDebug() {
    npaw.Log.logLevel = npaw.Log.Level.VERBOSE;
  }

  initializeYoubora(initoptions) {
    try {
      this.setYouboraDebug();
      const options = {...{ accountCode: this.settingsService.youboraAccountCode}, ...initoptions};
      window.plugin = new npaw.Plugin(options);
    } catch(err) {
      console.log("Error Initializing Youbora");
      console.log(err);
    }
  }

  youborainitOptions(content, isLive) {
    const initOptions = {
      'content.isLive': isLive,
      'content.streamingProtocol': this.getStreamingType(content?.playback_url) ?? '',
      'content.drm': content?.is_drm,
      'user.name' : this.storageService.getLocalStore('u_id') ?? '',
      'app.name': this.settingsService?.youboraSiteName,
      'app.releaseVersion': environment.appVersion,
      'content.title': content?.title_en
    }
    return initOptions;
  }

  addAdapterToPlayer(player) {
    try {
      const adapterCode = JSON.stringify(youboraAdapaterCode);
      window.plugin.registerAdapter(player, adapterCode);
    } catch(err) {
      console.log(err);
    }
  }

  sendYouboraFatalError() {
    try {
      window.plugin.getAdapter().fireFatalError();
      window.plugin.removeAdapter();
    } catch(err) {
      console.log(err);
    }
  }

  getStreamingType(playbackUrl) {
    if (playbackUrl.match(/.mpd/g)) {
      return 'DASH';
    } else if (playbackUrl.match(/.m3u8/g)) {
      return 'HLS';
    } else {
      return 'MP4';
    } 
  }

  setPluginOptions(content, isLive, audio, subtitle) {
    try {
      const options = {
        'user.name' : this.storageService.getLocalStore('u_id') ?? '',
        'content.title': content.title_en ?? content.content_title,
        'content.isLive': isLive,
        'content.id': content.contentId,
        'content.resource': content.playback_url,
        'content.language': audio ?? '',
        'content.subtitles': subtitle ?? '',
        'content.streamingProtocol': this.getStreamingType(content.playback_url) ?? '',
        'content.drm': content.is_drm,
        'content.customDimension.1': content.content_id ? content.content_id : content.channel_id,
        'content.customDimension.2': content.title,
        'app.name': this.settingsService?.youboraSiteName,
        'app.releaseVersion': environment.appVersion,
        'device.id': this.storageService.getLocalStore('d_id') ?? '',
        'device.type': environment.deviceType
      }
      if(content.series_video) {
        options['content.tvShow'] = content.series_title;
        options['content.season'] = content.season_title;
        options['content.episodeTitle'] = content.content_title;
      }
      window.plugin.setOptions(options);
    } catch(err) {
      console.log(err);
    }
  }

  playVideo(value, status) {
      this.baseService.loaderSource.next(true);
      this.contentService.autoPlayVideo = true;
      if (status == 'play') {
        this.contentService.play = true;
      } else {
        this.contentService.start = true;
      }
      this.addGTMData(value);
      if (value.is_livechannel) {
        this.baseService.storeEPGValue(value.slug);
        this.router.navigate(['/live', value.slug]);
      } else if (value.is_series) {
        const seriesSlug = value?.seasons_slug || value?.series_slug;
        const contentSlug = value?.content_slug;
        this.router.navigate(['/series', seriesSlug, contentSlug]);
      } else {
        this.router.navigate(['/video', value.collection_slug, value.content_slug]);
      }
  }

  playTrailer(value) {
    this.baseService.loaderSource.next(true);
    this.contentService.trailer = value;
    this.addGTMData(value);
    if (value.is_series) {
      const seriesSlug = value?.seasons_slug || value?.series_slug;
      const contentSlug = value?.content_slug;
      this.router.navigate(['/series', seriesSlug, contentSlug]);
    } else {
      this.router.navigate(['/video', value.collection_slug, value.content_slug]);
    }
  }


  addGTMData(video) {
    if (this.categoryName) {
      const datalayerData = {
        event: 'GAEvent',
        eventCategory: 'Content Click',
        eventAction: this.categoryName,
        eventLabel: video.title,
        loginStatus: (this.storageService.getLocalStore('u_id') ? 'True' : 'False'),
        userId: (this.storageService.getLocalStore('u_id') ? this.storageService.getLocalStore('u_id') : ''),
        content: this.datalayerService.getCategoryDatalayer(video, 'content'),
        contentId: video.contentId,
        VideoCategory: this.datalayerService.getCategoryDatalayer(video, 'category'),
        notificationStatus: localStorage.getItem('emailNotify') ||  'False',
        region: (this.storageService.getLocalStore('region') ? this.storageService.getLocalStore('region') : ''),
        platform: this.baseService.browserType,
        userType: this.storageService.getLocalStore('user_type'),
        operator: this.storageService.getLocalStore('operator_name')
      };
      this.datalayerService.addDatalayer(datalayerData);
    }
    else if (this.searchData) {
      const datalayerData = {
        event: 'GAEvent',
        eventCategory: 'Search',
        eventAction: this.searchData,
        eventLabel: this.settingsService.virtualUrl,
        loginStatus: (this.storageService.getLocalStore('u_id') ? 'True' : 'False'),
        userId: (this.storageService.getLocalStore('u_id') ? this.storageService.getLocalStore('u_id') : ''),
        content: this.datalayerService.getCategoryDatalayer(video, 'content'),
        contentId: video.contentId,
        VideoCategory: this.datalayerService.getCategoryDatalayer(video, 'category'),
        notificationStatus: localStorage.getItem('emailNotify') ||  'False',
        region: (this.storageService.getLocalStore('region') ? this.storageService.getLocalStore('region') : ''),
        platform: this.baseService.browserType,
        userType: this.storageService.getLocalStore('user_type'),
        operator: this.storageService.getLocalStore('operator_name')
      };
      this.datalayerService.addDatalayer(datalayerData);
    }
    else {
      const datalayerData = {
        event: 'GAEvent',
        eventCategory: 'Content Click',
        eventAction: (video.collection_title) ? video.collection_title : video.seasons_title,
        eventLabel: video.title,
        contentId: video.contentId,
        loginStatus: (this.storageService.getLocalStore('u_id') ? 'True' : 'False'),
        userId: (this.storageService.getLocalStore('u_id') ? this.storageService.getLocalStore('u_id') : ''),
        notificationStatus: localStorage.getItem('emailNotify') ||  'False',
        region: (this.storageService.getLocalStore('region') ? this.storageService.getLocalStore('region') : ''),
        content: this.datalayerService.getCategoryDatalayer(video, 'content'),
        VideoCategory: this.datalayerService.getCategoryDatalayer(video, 'category'),
        platform: this.baseService.browserType,
        userType: this.storageService.getLocalStore('user_type'),
        operator: this.storageService.getLocalStore('operator_name')
      };
      this.datalayerService.addDatalayer(datalayerData);
    }
  }

  redirect(video, key) {
    this.addGTMData(video);
    this.baseService.loaderSource.next(true);
    if (video?.is_livechannel) {
      this.baseService.storeEPGValue(video.slug);
      this.router.navigate(['/live', video.slug]);
    } else if (video?.is_series) {
      const seriesSlug = video?.seasons_slug || video?.series_slug;
      const contentSlug = video?.content_slug;
      if (video.watched_duration) {
        this.contentService.contentSlug = contentSlug;
        this.contentService.seriesSlug = seriesSlug;
        this.contentService.seekValue = video.watched_duration;
      } else {
        this.contentService.seekValue = 0;
      }
      this.router.navigate(['/series', seriesSlug, contentSlug]);
    } else if (key === 'video' && this.pageDetail === 'live') {
      this.router.navigate(['/live/player', 'video', video.content_id]);
    } else {
      this.router.navigate(['/video', video?.collection_slug, video?.content_slug]);
    }
  }

  removeWishlist(content) {
    const contentType = content.is_series && content.is_audio ? 'podcast' : content.is_series && !content.is_audio ? 'series' :!content.is_series && content.is_audio ? 'audio' :'video';
    const paramsData: object = {
      req: { content_type: contentType, id: content.content_id },
    };
    const removeMethod = content.is_series && !content.is_audio? this.wishListService.removeSeriesWishList(paramsData) : this.wishListService.removeWishlist(paramsData);
    removeMethod.subscribe(
      (data) => {
        if (data) {
          this.homeService.updateUserContentFlag = true;
          this.homeService.updateUserContent.next(true);
          if (content.is_series) {
            this.wishListService.removeFlagOn = true;
          }
        }
      }
    );
  }
  

  addToWishlist(video) {
    const contentType = video.is_series && video.is_audio ? 'podcast' : video.is_series && !video.is_audio ? 'series' :!video.is_series && video.is_audio ? 'audio' :'video';
    const addWishlistData = { content_id: video.content_id, content_type: contentType, version:'v1' };
    this.wishListService.addToWishlist(addWishlistData).subscribe((data) => {
      if (data) {
        //this.scrollTopValue = this.baseService.getScrollHeightValue();
        this.homeService.updateUserContentFlag = true;
        this.homeService.updateUserContent.next(true);
        this.gtmAddWishlist(contentType, video.title, video.content_id);
      }
    }, () => {

    }, () => {

    });
  }

  gtmAddWishlist(type, title, id) {
    const gtmType = type === 'series' ? 'Series' : 'Movies';
    const u_id = this.storageService.getLocalStore('u_id');
    const datalayerData = {
      event: 'GAEvent',
      eventCategory: 'Add Wishlist',
      contentId: type === 'video' ? id : '',
      content: `${gtmType}|` + title + '|' + this.storageService.getLocalStore('localeName'),
      VideoCategory: `VOD - ${gtmType} - Wishlist`,
      eventAction: title,
      eventLabel: '',
      loginStatus: (u_id ? 'True' : 'False'),
      userId: (u_id ? u_id : ''),
      notificationStatus: localStorage.getItem('emailNotify') ||  'False',
      region: (this.storageService.getLocalStore('region') ? this.storageService.getLocalStore('region') : ''),
      platform: this.baseService.browserType,
      userType: this.storageService.getLocalStore('user_type'),
      operator: this.storageService.getLocalStore('operator_name')
    };
    this.datalayerService.addDatalayer(datalayerData);
  }

  updateWishlistData() {
    const uId = this.storageService.getLocalStore('u_id');
    if (uId) {
      this.homeService.updateUserContent
        .subscribe(async (emitFlag) => {
          if (emitFlag && this.homeService.updateUserContentFlag) {
            this.homeService.updateUserContentFlag = false;
            this.homeService.userContent()
            .pipe(last())
            .subscribe(async(data) => {
              if (data) {
                const userData = await data;
                let wishListData=[];
                let audioWishListData=[];
                this.homeService.userData.next(userData);
                userData.forEach(personaData => {
                  if (personaData['key'] === "wishlist") {
                    wishListData = personaData['content_ids'] || [];
                    this.homeService.updateAudioVideoWishlistData('wishListData',personaData.list);
                  }
                  if(personaData['key'] === 'audio_wishlist'){
                    audioWishListData = personaData['content_ids'] || [];
                    this.homeService.updateAudioVideoWishlistData('audioWishListData',personaData.list);
                  }
                });
                let concatedWishListdata = [...wishListData, ...audioWishListData];
                this.homeService.contentWishlist.next(concatedWishListdata);
                this.baseService.loaderSource.next(false);
              }
            },() =>{
              this.baseService.loaderSource.next(false);
            })
          }
        },() =>{
          this.baseService.loaderSource.next(false);
        });
    }
  }
  redirectEPGContent(program) {
    if(program?.is_series === 0) this.router.navigate(['/video', 'epg', program.content_slug]);
    else {
      const series_slug = (program.season_slug != '') ? program.season_slug : program.series_slug;
      this.router.navigate(['/series', series_slug, program.content_slug]);
    }
  }

  loginRoute(){
    this.storageService.setLocalStore('contentURL', location.href);
    this.router.navigateByUrl('/auth/signin');
  }

}
