import { Pipe, PipeTransform } from '@angular/core';
import { ConfigurationService } from 'src/app/core/services/configuration.service';

@Pipe({
  name: 'movieDuration',
  standalone: true,
})
export class MovieDurationPipe implements PipeTransform {
  constructor(private configService: ConfigurationService) { }
  transform(data) {
    let min = data;
    if (min > 60) {
      let hours = Math.floor(min / 60);
      let minutes = min % 60;
      return `${hours} ${this.configService?.localeKeys?.["common_keys.hr_letter.text"] || 'h'} ${minutes} ${this.configService?.localeKeys?.["common_keys.min_letter.text"] || 'm'}`
    } else {
      return `${min} ${this.configService?.localeKeys?.["common_keys.min_letter.text"] || 'm'}`
    }
  }

}
