<!-- below block is for mini player both web and mobile responsive -->
@if(showPlayer){
    <!-- below block is for web page audio player -->
    @if(this.windowWidth > 1024){
        <div class="audio-podcast-container" [ngClass]="{'show-player':showPlayer}">
            <div class="audio-podcast-duration-container">
                <div class="miniplayer-time">{{currentPlayingTime}}</div>
                <div class="miniplayer-time">{{totalPlayingTime}}</div>
            </div>
            <div class="audio-podcast-container-detail">
                <div class="audio-podcast-left">
                    @if(currentSelectedType == 'audio'){
                        <img class="audio-img" [src]="settingsService.getImage(currentContentAssetData?.poster_square, 'card')">
                        <div class="audio-left-detail">
                            <span class="title">{{currentContentAssetData.title}}</span>
                            <span class="casting">{{currentContentAssetData.cast}}</span>
                        </div>
                    }
                    @if(currentSelectedType == 'podcast'){
                        <img class="audio-img" [src]="settingsService.getImage(currentPlayingEpisode?.poster_square, 'card')">
                        <div class="audio-left-detail">
                            <span class="title">{{currentPlayingEpisode?.title}}</span>
                            <div class="date-detail">
                                <span class="queue-date-time">{{currentPlayingEpisode?.publish | queueListDate}} | </span>
                                <span class="queue-date-time">{{currentPlayingEpisode?.duration_sec | queueListDuration}}</span>
                            </div>
                        </div>
                    }
                </div>
                <div class="audio-podcast-centre">
                    <div class="control-icons">
                        @if(currentSelectedType == 'audio'){
                            <img class="audio-shuffle-icon control-icons-opacity" [ngClass]="{'add-opacity':shuffle}" [class.disableIcon]="queueList.length == 0" (click)="shuffleEvent()" src="branding/theme/assets/static_assets/ic-shuffle-on-dbg.svg">
                            <img class="audio-prev-icon control-icons-opacity" [class.disableIcon]="(position == 0 && !audioRepeat)" (click)="prevSong()" src="branding/theme/assets/static_assets/ic-previous-on-dbg.svg">
                        }
                        @if(currentSelectedType == 'podcast'){
                            <img class="audio-backskip-icon control-icons-opacity" (click)="audioSkipBackward(15)" src="branding/theme/assets/static_assets/ic-rewind-15-on-dbg.svg">
                        }
                        @if(!isPlaying){
                            <img (click)="playerPlay()" class="audio-play-icon" src="branding/theme/assets/static_assets/ic-aud-pod-play-on-dbg.svg">
                        }@else{
                            <img (click)= "playerPause()" class="audio-pause-icon" src="branding/theme/assets/static_assets/ic-aud-pod-pause-on-dbg.svg">
                        }
                        @if(currentSelectedType == 'podcast'){
                            <img class="audio-forwardskip-icon control-icons-opacity" (click)="audioSkipForward(15)" src="branding/theme/assets/static_assets/ic-forward-15-on-dbg.svg">
                        }
                        @if(currentSelectedType == 'audio'){
                            <img class="audio-next-icon control-icons-opacity" [class.disableIcon]="(!this.audioRepeat && (this.position == (this.playlistContent.length-1)))" (click)="nextSong()" src="branding/theme/assets/static_assets/ic-next-on-dbg.svg">
                            <img class="audio-repeat-icon control-icons-opacity"[class.add-opacity]="audioRepeat" [class.disableIcon]="queueList.length == 0" (click)="repeat()" src="branding/theme/assets/static_assets/ic-repeat-on-dbg.svg">
                        }
                    </div>
                </div>
                <div class="audio-podcast-right">
                    @if(!queueListOpen){
                        <div class="round-container" (click)="addToWishlist()">
                            <img class="audio-like-icon" src="branding/theme/assets/static_assets/ic-aud-pod-empty-heart-on-dbg.svg">
                        </div>
                        <div class="round-container" (click)="openUikitDialog('#audio-playlist')">
                            <img class="audio-expand-icon" src="branding/theme/assets/static_assets/ic-aud-pod-full-screen-on-dbg.svg">
                        </div>
                    }
                    <div class="round-container volume-round-container" (click)="muteUnmute()">
                        <img class="audio-volume-icon" [src]="volumeIcon">
                        <div class="volume-control">
                            <input type="range" min="0" max="1" step="0.01" (input)="setVolume($event)" (click)="$event.stopPropagation()"  class="volume-slider" orient="vertical">
                        </div>
                    </div>
                    <div class="round-container close-container" (click)="closePlayer()">
                        <img class="audio-close-icon" src="branding/theme/assets/static_assets/ic-aud-pod-quit-player-2-on-dbg.svg">
                    </div>
                </div>
            </div>
            <div #progressBarContainer class="audio-podcast-progressbar-container">
                <div #progressBar class="audio-podcast-progress-bar">
                    <div class="progress-bar-end"></div>
                </div>
        
            </div>   
        </div>
    }
    <!-- below block is for mobile responsive audio player -->
    @if(this.windowWidth <= 1024){
        <div #audioPodcastMobileContainer class="audio-podcast-container-mobile" [ngClass]="{'show-mob-player':showPlayer, 'mobpage-height':showMobScreen}">
            <!-- below block is mini player code in mobile responsive -->
            @if(!showMobScreen){
                <div class="mob-mini-player-container">
                    <div class="mob-mini-player-content-container">
                        <div class="mob-audio-podcast-left" (touchstart)="onTouchStart($event)"  >
                            @if(currentSelectedType == 'audio'){
                                <img class="mob-audio-img" [src]="settingsService.getImage(currentContentAssetData?.poster_square, 'card')">
                                <div class="mob-audio-left-detail">
                                    <span class="mob-title">{{currentContentAssetData.title}}</span>
                                    <span class="mob-casting">{{currentContentAssetData.cast}}</span>
                                </div>
                            }
                            @if(currentSelectedType == 'podcast'){
                                <img class="mob-audio-img" [src]="settingsService.getImage(currentPlayingEpisode?.poster_square, 'card')">
                                <div class="audio-left-detail">
                                    <span class="mob-title">{{currentPlayingEpisode?.title}}</span>
                                    <div class="mob-date-detail">
                                        <span class="mob-queue-date-time">{{currentPlayingEpisode?.publish | queueListDate}} | </span>
                                        <span class="mob-queue-date-time">{{currentPlayingEpisode?.duration_sec | queueListDuration}}</span>
                                    </div>
                                </div>
                            }
                        </div>
                        <div class="mob-audio-podcast-right">
                            <div class="round-container">
                                <img class="mob-audio-close-icon" (click)="closePlayer()" src="branding/theme/assets/static_assets/ic-aud-pod-quit-player-2-on-dbg.svg">
                            </div>
                            @if(!isPlaying){
                                <div class="round-container">
                                    <img (click)="playerPlay()" class="mob-audio-play-icon" src="branding/theme/assets/static_assets/ic-aud-pod-play-on-dbg.svg">
                                </div>
                            }@else{
                                <div class="round-container">
                                    <img (click)= "playerPause()" class="omob-audio-pause-icon" src="branding/theme/assets/static_assets/ic-aud-pod-pause-on-dbg.svg">
                                </div>
                            }
                        </div>
                    </div>
                    <div #progressBarContainer class="mob-audio-podcast-progressbar-container">
                        <div #progressBar class="mob-audio-podcast-progress-bar">
                            <div class="mob-progress-bar-end"></div>
                        </div>               
                    </div> 
                </div>
            }
            <!-- below block is full screen for player in mobile responsive . it will contain quelist and current playing content's controls -->
            @if(showMobScreen){
                @if(!mobileViewallExpand){
                    <div class="mobscreen-top">
                        <img class="scroll-down-arrow" (click)="scrollDown()" src="branding/theme/assets/static_assets/ic-down.svg">
                        <img class="current-playing-img" [src]="settingsService.getImage(currentContentAssetData?.poster_square, 'card')" >
                        <div class="mobscreen-top-details-container">
                            <div class="mobscreen-top-details-container-left">
                                @if(currentSelectedType == 'audio'){
                                    <span class="mobscreen-top-title">{{currentContentAssetData.title}}</span>
                                    <span class="mobscreen-top-casting">{{currentContentAssetData.cast}}</span>
                                }
                                @if(currentSelectedType == 'podcast' && !mobScreenEpisodePlayTrigger){
                                    <span class="mobscreen-top-title">{{currentContentAssetData.title}}</span>
                                    <div class="mobscreen-top-tags">
                                        @for(item of currentContentAssetData.genre; track item){
                                            <span>{{item}}</span>
                                            @if(!$last){
                                                <span> | </span>
                                            }
                                        }
                                    </div>
                                }
                                @if(currentSelectedType == 'podcast' && mobScreenEpisodePlayTrigger){
                                    <span class="mobscreen-top-title">{{mobScreenCurrentEpisodeTitle}}</span>
                                    <span class="mobscreen-top-casting">{{currentContentAssetData.title}}</span>
                                }
                            </div>
                            <div class="mobscreen-top-details-container-right">
                                <img class="mobscreen-top-mylist-img" (click)="addToWishlist()" src="branding/theme/assets/static_assets/ic-aud-pod-empty-heart-on-lbg.svg">
                            </div>
                        </div>
                    </div>
                    <div class="mobscreen-play-controls-container" [ngClass]="{'audiopodcast-control-height':((currentSelectedType == 'audio') || (mobScreenEpisodePlayTrigger && currentSelectedType == 'podcast') )}">
                        @if(mobScreenEpisodePlayTrigger && currentSelectedType == 'podcast'){
                            <div #progressBarContainer class="mob-audio-podcast-progressbar-container">
                                <div #progressBar class="mob-audio-podcast-progress-bar">
                                    <div class="mob-progress-bar-end"></div>
                                </div>               
                            </div> 
                            <div class="mobscreen-play-controls">
                                <img class="audio-backskip-icon control-icons-opacity" (click)="audioSkipBackward(15)" src="branding/theme/assets/static_assets/ic-rewind-15-on-lbg.svg">
                                @if(!isPlaying){
                                    <img (click)="playerPlay()" class="audio-play-icon" src="branding/theme/assets/static_assets/ic-aud-pod-play-on-lbg.svg">
                                }@else{
                                    <img (click)="playerPause()" class="audio-pause-icon" src="branding/theme/assets/static_assets/ic-aud-pod-pause-on-lbg.svg">
                                }
                                <img class="audio-forwardskip-icon control-icons-opacity" (click)="audioSkipForward(15)" src="branding/theme/assets/static_assets/ic-forward-15-on-lbg.svg">
                            </div>
                        }
                        @if(currentSelectedType == 'audio'){
                            <div #progressBarContainer class="mob-audio-podcast-progressbar-container">
                                <div #progressBar class="mob-audio-podcast-progress-bar">
                                    <div class="mob-progress-bar-end"></div>
                                </div>               
                            </div>
                            <div class="mobscreen-play-controls">
                                <img class="audio-shuffle-icon control-icons-opacity" [ngClass]="{'add-opacity':shuffle}" [class.disableIcon]="queueList.length == 0" (click)="shuffleEvent()" src="branding/theme/assets/static_assets/ic-shuffle-on-lbg.svg">
                                <img class="audio-prev-icon control-icons-opacity" [class.disableIcon]="(position == 0 && !audioRepeat)" (click)="prevSong()" src="branding/theme/assets/static_assets/ic-previous-on-lbg.svg">
                                @if(!isPlaying){
                                    <img (click)="playerPlay()" class="audio-play-icon" src="branding/theme/assets/static_assets/ic-aud-pod-play-on-lbg.svg">
                                }@else{
                                    <img (click)="playerPause()" class="audio-pause-icon" src="branding/theme/assets/static_assets/ic-aud-pod-pause-on-lbg.svg">
                                }
                                <img class="audio-next-icon control-icons-opacity" [class.disableIcon]="(!this.audioRepeat && (this.position == (this.playlistContent.length-1)))" (click)="nextSong()" src="branding/theme/assets/static_assets/ic-next-on-lbg.svg">
                                <img class="audio-repeat-icon control-icons-opacity"[class.add-opacity]="audioRepeat" [class.disableIcon]="queueList.length == 0" (click)="repeat()" src="branding/theme/assets/static_assets/ic-repeat-on-lbg.svg">
                            </div>
                        }     
                    </div>
                    @if(currentSelectedType == 'audio'){
                        <div class="mob-screen-queue-list">
                            <div class="mob-queue-top-section">
                                <span class="mob-queue-top-section-title">Queue</span>
                                @if((queueList.length > maxLengthToHide)){
                                    <span class="mob-queue-top-section-viewall" (click)="expandMobieViewall()">View All</span>
                                }
                            </div>
                            <div class="mob-queue-list-section">
                                @for(content of queueList;track content){
                                    @if($index  < maxLengthToHide ){
                                        <div class="mob-queue-list-item" (click)="audioPlayEvent($index)">
                                            <img class="mob-queue-list-item-img" [src]="settingsService.getImage(content?.poster_square, 'card')">
                                            <div class="mob-queue-list-item-details">
                                                <span class="mob-queue-list-item-title">{{content?.title}}</span>
                                                <span class="mob-queue-list-item-cast">{{content?.cast}}</span>
                                            </div>
                                        </div>
                                    }
                                }
                            </div>
                        </div>
                    }
                    @if(currentSelectedType == 'podcast'){
                        <div class="queue-podacst-header">
                            <div class="queue-podcast-header-title" [class.selected-tile]="queuelistChoosen == 'episode'" (click)="changeChoosenTypeFunc('episode')">{{"Episodes"}}</div>
                            <div class="queue-podcast-header-title" [class.selected-tile]="queuelistChoosen == 'about'" (click)="changeChoosenTypeFunc('about')">{{"About"}}</div>
                        </div>
                        @if(queuelistChoosen == 'episode'){
                            <div class="mob-queue-list-section">
                                @for(content of queueList;track content){
                                    <div class="mob-queue-list-item">
                                        <img class="mob-queue-list-item-img" [src]="settingsService.getImage(content?.poster_square, 'card')">
                                        <div class="mob-queue-list-item-details">
                                            <span class="mob-queue-list-item-title">{{content?.title}}</span>
                                            <div class="date-detail">
                                                <span class="queue-date-time">{{content?.publish | queueListDate}} | </span>
                                                <span class="queue-date-time">{{content?.duration_sec | queueListDuration}}</span>
                                            </div>
                                        </div>
                                        @if(!isPlaying || (currentPlayingEpisodeIndex == $index)){
                                            <img (click)="episodePlayEvent($index)" class="audio-play-icon" src="branding/theme/assets/static_assets/ic-play-round-filled.svg">
                                        }@else if(isPlaying && (currentPlayingEpisodeIndex == $index)){
                                            <img (click)="playerPause()" class="audio-pause-icon" src="branding/theme/assets/static_assets/ic-pause-round-filled.svg">
                                        }
                                    </div>
                                }
                            </div>
                        }
                        @if(queuelistChoosen == 'about'){
                            <div class="mob-queue-list-about">
                                <p class="mob-queue-list-about-text">{{currentContentAssetData?.description}}</p>
                            </div>
                        }
                    }
                }
                @if(mobileViewallExpand){
                    <div class="mobscreen-top">
                        <div class="back-container">
                            <img class="scroll-back-arrow" (click)="goBack()" src="branding/theme/assets/static_assets/ic-left-arrow-on-lbg.svg">
                            <span class="mobscreen-top-back">Back</span>
                        </div>
                        <div class="mobscreen-top-queue-heading">
                            <span>My Queue</span>
                        </div>
                        <div class="mobscreen-top-current-container">
                            <span class="mobscreen-top-current-container-title">Current Playing</span>
                            <div class="mob-queue-list-item">
                                <img class="mob-queue-list-item-img" [src]="settingsService.getImage(currentContentAssetData?.poster_square, 'card')">
                                <div class="mob-queue-list-item-details">
                                    <span class="mob-queue-list-item-title">{{currentContentAssetData?.title}}</span>
                                    <span class="mob-queue-list-item-cast">{{currentContentAssetData?.cast}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="mobscreen-top-viewall-queue-container">
                            <span class="mobscreen-top-current-container-title">Upcoming</span>
                            <div class="mob-queue-list-section-viewall">
                                @for(content of queueList;track content){
                                    <div class="mob-queue-list-item" (click)="audioPlayEvent($index)">
                                        <img class="mob-queue-list-item-img" [src]="settingsService.getImage(content?.poster_square, 'card')">
                                        <div class="mob-queue-list-item-details-viewall">
                                            <span class="mob-queue-list-item-title">{{content?.title}}</span>
                                            <span class="mob-queue-list-item-cast">{{content?.cast}}</span>
                                        </div>
                                        <div class="mobscreen-top-details-container-right">
                                            <img class="mobscreen-top-mylist-img" (click)="addToWishlist()" src="branding/theme/assets/static_assets/ic-aud-pod-empty-heart-on-lbg.svg">
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                }        
            }
        </div>
    }
}

<!-- audio tag for playing the audio url. but all the controls for audio is handling in custom code in abobe sections -->
<audio #audioPlayer controls  class="audio-player">
    <source [src]="currentPlayingContentUrl" type="audio/mpeg"/>
</audio>

<!-- below block is for queuelist popup in web page not for mobile responsive -->
<div id="audio-playlist" [class.without-mini]="!showPlayer" class="uk-flex-top acc-modal" uk-modal="esc-close: false; bg-close: false;">
    <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical audio-matdialog">
        <button class="uk-modal-close-default uk-close-large" type="button" uk-close (click)="closemodal()" >
            <img src="branding/theme/assets/static_assets/ic-aud-pod-minimise-on-dbg.svg" alt="Close" class="custom-close-icon">
        </button>
        <app-audio-playlist (audioSongInit)="audioPlayEvent($event)" (episodeSongInit)="episodePlayEvent($event)" [personaApiFail]="personaApiFail" [audioCollectionApiFail]="audioCollectionApiFail" [totalPlaylistContent]="playlistContent" [isPlaying]="isPlaying"  [currentPlayingContent]="currentContentAssetData" [currentPlayingCollection]="currentCollection" [queData]="queueList" [currentPlayingPosition]="position"></app-audio-playlist>
    </div>
</div>