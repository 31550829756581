import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { StorageService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class HooplaGuard  {

  constructor(private router: Router, private storageService: StorageService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const hooplaUser = this.storageService.getLocalStore('hoopla_user');
      if (!hooplaUser) {
          return true;
      } else {
          this.router.navigateByUrl('/home');
          return false;
      }
  }

}
