import { Component, HostListener, OnInit } from '@angular/core';
import { BaseService, SettingsService, StorageService } from '../services';
import { Router } from '@angular/router';
import { ConfigurationService } from '../services/configuration.service';
import { CategoriesService } from '../services/categories.service';
import { ContentService } from '../services/content.service';
import { environment } from 'src/environment';
import { LoggerService } from '../authentication/logger.service';
import { FooterService } from 'src/app/core/services/footer.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  selectedLocale: any;
  selectedLocaleKey: any;
  locales: any;
  localeLength: any;
  showLanguageTop = true;
  dropPosBottom = true;
  prevHeight: number;
  isMobile: boolean;
  innerWidth;
  feedbackEnabled;
  restrictedUser;
  footercontents: any;
  skinName = environment.skinName;
  constructor(public configService:ConfigurationService, private storageService:StorageService,
              public settingsService: SettingsService, public categoryService: CategoriesService,
              public baseService: BaseService, private router: Router, private contentService: ContentService,
              private loggerService: LoggerService, private footerService:FooterService ) {
    this.selectedLocaleKey = this.configService.locales[this.configService.localeIndex].lang_code;
    this.locales = this.configService.locales;
    this.localeLength = this.locales.length;
    this.feedbackEnabled = this.settingsService.feedbackEnabled;
    if (this.localeLength <6) this.dropPosBottom = false;
    this.checkLangPostion();
    this.prevHeight = window.innerHeight;
    this.innerWidth = window.innerWidth;
    this.isMobile = this.baseService.isMobileOrNot();
    this.footercontents = {
      faq: false,
      contact: false,
      terms: false,
      privacy: false,
      about: false,

    };
    this.isFooterColsVisible();
    this.restrictedUser = environment.isEzProxy || (this.storageService.getLocalStore('hoopla_user') == '1') ? true :false;
  }

  async ngOnInit() {
    await this.contentService.translateSelectedLocale().then((selectedLocale) =>  {
      this.selectedLocale = selectedLocale;
    });
    this.locales = this.contentService.translateLanguage(this.locales);
    this.loggerService.hooplaUser.subscribe(data => {
      if (data === true) this.restrictedUser = true;
    });
  }

  isFooterColsVisible() {
    if (this.footerService.pageList?.length) {
      Object.entries(this.footercontents).forEach((item: any) => {
        const isPresent = this.footerService.pageList.find((obj: any) => obj.type === item[0]);
        if (isPresent) this.footercontents[item[0]] = true;
        else this.footercontents[item[0]] = false;
      });
    }
  }
  
// this method selection for laguage
  setLocale(i, value) {
    this.selectedLocale = value.translatedLanguage;
    this.selectedLocaleKey = value.lang_code;
    this.configService.localeIndex = i;
    this.storageService.setLocalStore('locale', this.selectedLocaleKey);
    this.storageService.setLocalStore('localeName', this.selectedLocale);
    this.configService.setlocaleCookie(this.selectedLocaleKey);
    this.configService.locale = this.selectedLocaleKey;
    window.location.reload();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkLangPostion();
    this.innerWidth = window.innerWidth;
    this.isMobile = this.baseService.isMobileOrNot();
  }

  checkLangPostion(){
    if(window.innerWidth< 560) this.showLanguageTop = false;
    else this.showLanguageTop = true;
  }
// this method redirct to feedback componet.
  feedbackRedirect() {
    let feedbackURL = '/app/feedback';
    this.router.navigateByUrl(feedbackURL)
  }

  redirect(value) {
    window.scrollTo({top: 0,behavior: 'smooth'});
    this.baseService.newCategoryRedirection(value);
  }

}
