import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { SettingsService } from 'src/app/core/services';
import { PlayContentService } from 'src/app/core/services/play-content.service';
import { QueueListDurationPipe } from '../../pipes/queue-list-duration.pipe';
import { QueueListDatePipe } from '../../pipes/queue-list-date.pipe';

@Component({
  selector: 'app-audio-playlist',
  standalone: true,
  imports: [QueueListDurationPipe, QueueListDatePipe],
  templateUrl: './audio-playlist.component.html',
  styleUrl: './audio-playlist.component.scss'
})
export class AudioPlaylistComponent implements OnInit, OnChanges {
 @Input()currentPlayingContent;
 @Input()currentPlayingCollection;
 @Input()queData;
 @Input()currentPlayingPosition;
 @Input()isPlaying;
 @Input()totalPlaylistContent;
 @Input()personaApiFail;
 @Input()audioCollectionApiFail;
 @Output()episodeSongInit = new EventEmitter();
 @Output()audioSongInit = new EventEmitter();
 currentPlayingType = '';
 selectedType='episode';
 currentPlayingEpisodeIndex;
 maxLengthToHide = 5;
 hideQueueLength = this.maxLengthToHide;
 hideQueueContent = this.maxLengthToHide;
 queueExpanded = false;

  constructor(public settingsService:SettingsService, private playContentService:PlayContentService){
    this.playContentService.selectedContentType.subscribe(data => this.currentPlayingType = data);
  }

  ngOnChanges(changes: SimpleChanges): void {}

  ngOnInit(): void {}

  changeType(type){
    this.selectedType = type;
  }

  podcastPlayInit(position){
    this.currentPlayingEpisodeIndex = position;
    this.episodeSongInit.emit(position);
  }

  audioPlayInit(position){
    this.audioSongInit.emit(position);
  }

  podcastPlayPause(position){}

  addToWishlist(){
    this.playContentService.addToWishlist(this.currentPlayingContent);
  }

  expandViewall(){
    this.queueExpanded = true;
    this.hideQueueContent = this.totalPlaylistContent.length;
  }
  shrinkViewall(){
    this.queueExpanded = false;
    this.hideQueueContent = this.maxLengthToHide;
    this.hideQueueLength = this.maxLengthToHide;
  }
}