<div [class]="skinName">
    <nav [ngClass]="{'navbar-top': showUpdateCard}"  class="navbar navbar-default navbar-fixed-top nav-scroll" role="navigation" >
      <div class="header_section" id="header-section">
        <div id="header-pad" class="d-align-center">
          <div class="d-align-center" id="check">
            <div class="navbar-header">
              <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#main-menu" aria-expanded="false">
                <span class="sr-only">Toggle navigation</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
              </button>
              <a class="home-logo" [ngClass]="{'cursor-none':(hideHandInLogo && (user_id || !settingsService.paywallEnabled)), 'hide-logo':!showLogo, 'show-logo':showLogo}"><img (click)="redirectURL()" src="branding/theme/assets/dynamic_assets/logo-lbg.png"></a>
            </div>
            <div class="collapse navbar-collapse" id="main-menu">
              <ul (mouseleave)="hoverOutClass()"  class="nav navbar-nav left-align" id="listCategory">
                @for(value of (categoryService.categories | async); track value; let a=$index){
                  <li class="dropdown category-list"   id="{{ 'object-' + a }}"  (mouseover)="hoverIn(value, a)">
                    <a (click)="redirect(value.slug, value)" [ngClass]="{'active-category': value.slug == selectedMenuItem}" class="dropdown-toggle category" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                      <span>{{value.title}}</span>
                      @if(value.sub_menus.length > 0){<span class="caret"></span>}
                    </a>
                      @if(value.slug === catName && categorySelected){<img class="category-arrow-asset"  src="branding/theme/assets/static_assets/ic-up-filled.svg">}
                        <div class="dropdown-menu">
                          <div class="container-fluid" [hidden]="!categorySelected"  [ngClass]="{'category-hover': (categorySelected==true)}">
                            <div id="dropdown-menu-header" class="subcategory-list">
                              <div class="content">
                                <div class="row all-items">
                                  @for(submenu of categoryInfo; track submenu; let q = $index){
                                    <ng-container >
                                      @if(submenu?.lists?.length){
                                        <div class="sub-menu-card" >
                                          @if(submenu.display_title =='1'){<h6 (click)="redirectToViewAll(submenu)"class="sub-menu-header">{{ configService.localeKeys[submenu.submenu_translation_key] || submenu.submenu_title}} </h6>}
                                          @if ((submenu.submenu_content_row*submenu.submenu_content_col) < submenu.count ? submenu.submenu_content_col : ceilData(submenu.count/submenu.submenu_content_row); as col) {
                                            <div>
                                              @for(loop3 of counter(col); track loop3; let i= $index){
                                                <ul [ngStyle]="{'grid-template-rows': 'repeat(' + submenu.submenu_content_row + ', auto)' }" class="subcategory-list-items" >
                                                  @if (submenu.submenu_content_row > submenu.count ? submenu.count : submenu.submenu_content_row; as row) {
                                                    @for (contents of counter(row); track contents; let j = $index) {
                                                      @if((((i * submenu.submenu_content_row) + j) < ((submenu.count > submenu.submenu_content_row*submenu.submenu_content_col) ? ((submenu.submenu_content_row*submenu.submenu_content_col)-1) : (submenu.submenu_content_row*submenu.submenu_content_col)) && (submenu.lists[(i*submenu.submenu_content_row)+j]?.title  || submenu.lists[(i*submenu.submenu_content_row)+j]?.collection_title))){
                                                        <li class="list-items-style" >
                                                          @if(submenu.lists[(i*submenu.submenu_content_row)+j]?.is_collection == '1'){
                                                            <a (click)="redirectSubmenu(submenu.lists[(i*submenu.submenu_content_row)+j], submenu)">{{submenu.lists[(i*submenu.submenu_content_row)+j]?.collection_title}}</a>}
                                                            @if(submenu.lists[(i*submenu.submenu_content_row)+j]?.is_series == '1'){
                                                              <a (click)="redirectSubmenu(submenu.lists[(i*submenu.submenu_content_row)+j])"> {{submenu.lists[(i*submenu.submenu_content_row)+j]?.title}} </a>
                                                            }
                                                            @if(submenu.lists[(i*submenu.submenu_content_row)+j]?.is_livechannel == '1'){
                                                              <a (click)="redirectSubmenu(submenu.lists[(i*submenu.submenu_content_row)+j])"> {{submenu.lists[(i*submenu.submenu_content_row)+j]?.title}} </a>
                                                            }
                                                          </li>}
                                                        }
                                                      }
                                                      @if (submenu?.count > (submenu.submenu_content_row*submenu.submenu_content_col) && ((i+1)==submenu.submenu_content_col)) {
                                                        <li><a class="view-all-style" (click)="redirectToViewAll(submenu)"> {{ configService.localeKeys?.homepg_menu_viewall || 'View all'}} </a></li>
                                                      }
                                                    </ul>}
                                                  </div>
                                                }
                                              </div>}
                                            </ng-container>}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              }
                              @if(!(baseService.guest | async) && !restrictedUser){
                                <li (mouseover)="myListHover()" class="category-list">
                                  <a [routerLink]="['/wishlist']" [queryParams]="{menu_item: 'wishlist'}" (click)="onWishlistClick('wishlist')" class="nav-no-border-bottom" [ngClass]="{'active-category': selectedMenuItem == 'wishlist'}">{{ configService.localeKeys?.['common_keys.my_list.text']}}</a>
                                </li>
                              }
                            </ul>
                          </div><!-- /.navbar-collapse -->
                        </div>
                        <ul class="nav navbar-nav navbar-right right-align d-align-center">
                          <li>
                            <form id="form" method="post" name="searchForm">
                              <input #inputSearchElement id="inputSearch" (click)="inputClick($event)" class="search-field" (blur)="focusOutInput($event)" (focus)="focusInput($event)" placeholder="{{ configService.localeKeys?.['search.placeholder_text.text'] }}" type="search" name="searchName" [(ngModel)]="searchName" spellcheck="false" autocomplete="off">
                              <button id="searchIcon" class="search-box-xs searchButton" type="submit" (click)="searchShow($event)"><img loading="lazy" class="search-icon1" src="branding/theme/assets/static_assets/ic-search-on-lbg.svg">
                              </button>
                              <button id="cancelIcon" (click)="closeSearch()" class=" search-box-xs searchButton"><img loading="lazy" class="search-icon1" src="branding/theme/assets/static_assets/ic-cross-round-on-lbg.svg">
                              </button>
                            </form>
                          </li>
                          @if (!guest && !restrictedUser) {
                            <li class="dropdown nav-profile">
                              @if (!guest) {
                                <a class="dropdown-toggle cursor-pointer" data-toggle="dropdown">
                                  <div class="nav-icon-img" (click)="closeDrop()">
                                    <div class="profile-pic-container">
                                      @if (enableProfiles && profileInfo?.icon) {
                                        <div class="profile-pic"><img src="{{imagePath + profileInfo?.icon}}"></div>
                                      } @else if (enableProfiles && !profileInfo?.icon) {
                                        <div class="profile-pic"> {{((profileInfo.name).charAt(0)).toUpperCase()}} </div>
                                      } @else {
                                        <img loading="lazy" src="branding/theme/assets/static_assets/ic-login-via-email-on-lbg.svg">
                                      }
                                    </div>
                                  </div>
                                  <!-- {{userName}} -->
                                  <!-- <strong class="caret"></strong> -->
                                </a>
                              }
                              <ul class="dropdown-menu nav-profile-menu align-profile" (click)="closeSearch()">
                                <div class="border-bot more-profile">
                                  @if (enableProfiles) {
                                    <li class="profile-info">
                                      <a  class="cursor-default">
                                        <div class="dropdown-profile-pic">
                                        @if (profileInfo?.icon) {
                                          <div class="profile-pic"><img src="{{imagePath + profileInfo?.icon}}"></div>
                                        } @else {
                                          <div class="profile-pic"> {{((profileInfo.name).charAt(0)).toUpperCase()}} </div>
                                        }
                                        <span> {{profileInfo?.name}} </span>
                                        </div>
                                      </a>
                                    </li>
                                    <li>
                                      <a (click)="switchProfile()" >{{configService?.localeKeys?.['account.switch_profile.button'] || 'Switch Profile'}}</a>
                                    </li>
                                  }
                                  <li>
                                    <a [routerLink]="['/account/settings']">
                                      {{ configService.localeKeys?.['menu.my_account.text']}}
                                    </a>
                                  </li>
                                  <li>
                                    <a [routerLink]="['/wishlist']">
                                      {{ configService.localeKeys?.['common_keys.my_list.text']}}
                                    </a>
                                  </li>
                                  <li>
                                    <a [routerLink]="['/app/feedback']">
                                      {{ configService.localeKeys?.['feedback.feedback.text']}}
                                    </a>
                                  </li>
                                  <!-- <li>
                                  <a (click)="redirect('/account/authenticate')" ui-sref="profile.authenticate({region: region})">
                                    {{ configService.localeKeys?.['activate_tv.activate_tv.text']}}
                                  </a>
                                </li> -->
                                @if (settingsService.activateYourTvFromTVCode || settingsService.activateYourTvFromWebCode) {
                                  <li>
                                    <a (click)="activateCodeRedirect()">
                                      {{ configService.localeKeys?.['account.activate_tv.text']}}
                                    </a>
                                  </li>
                                }
                                <li>
                                  <a class="cursor-pointer" (click)="logout()">
                                    {{ configService.localeKeys?.['account.logout.btn']}}
                                  </a>
                                </li>
                              </div>
                            </ul>
                          </li>
                        }
                        @if (guest && !restrictedUser) {
                          <li>
                            @if (operatorLogin!=0) {
                              <a [routerLink]="['/login/option']" (click)="closeSearch()" class="login-btn">{{ configService.localeKeys?.['common_keys.login.text']}}</a>
                            }
                            @if (operatorLogin==0) {
                              <a [routerLink]="['/auth/signin']" (click)="closeSearch()" class="login-btn">{{ configService.localeKeys?.['common_keys.login.text']}}</a>
                            }
                          </li>
                        }
                      </ul>

                    </div>
                  </div>
                </nav>
            </div>