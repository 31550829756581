import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'queueListDate',
  standalone: true
})
export class QueueListDatePipe implements PipeTransform {

  transform(dateStr): unknown {
    const date = new Date(dateStr);

    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();
  
    const daySuffix = (day) => {
      if (day > 3 && day < 21) return 'th';
      switch (day % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
      }
    };
  
    return `${day}${daySuffix(day)} ${month} ${year}`;
  }

}
