import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SettingsService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class SvodFlagGuard  {
  constructor(private settingsService: SettingsService, private router: Router) {

  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if(this.settingsService.isSvodEnabled) return true;
    else this.router.navigateByUrl('/home');
  }
  
}
