<div [class]="skinName">
  <div class="h-100">
    <div class="logo-align">
      @if (settingsService.metroOnly) {
        <img class="logo-img" src="branding/theme/assets/dynamic_assets/logo-lbg.png">
      }
      @if (!settingsService.metroOnly) {
        <img [routerLink]="['/home']" class="logo-img cursor-pointer" src="branding/theme/assets/dynamic_assets/logo-lbg.png">
      }
    </div>
    <div class="error-bg-img">
      <div class="error-page">
        <div class="error-container card-bg">
          <div class="error-image">
            @if (errorResult?.image) {
              <img loading="lazy" [src]="errorResult.image" [alt]="errorResult.alt_image">
            }
          </div>
          <div class="error-header">
            <h2 class="error-text">{{errorResult.title}}</h2>
          </div>
          <div class="error-content">{{errorResult.description}}</div>
          @if (!settingsService.metroOnly) {
            <div class="home-redirection">
              <a [routerLink]="['/home']" ui-sref="profile.home({region: region})">
                <button class="take-home-button blue-btn">{{ configService.localeKeys?.err_btn_go_home_def}}</button>
              </a>
            </div>
          }
        </div>
      </div>
    </div>
  </div>

</div>