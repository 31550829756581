import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { token } from 'src/app/shared/constants';
import { CategoriesService } from './categories.service';
import { StorageService } from './storage.service';
import { environment } from 'src/environment';
import { SettingsService } from './settings.service';

@Injectable({
  providedIn: 'root'
})
export class LoadService {

  userId;
  deviceId;
  guestId;
  accessToken;
  memoryStorage;
  userType;
  dataLayer;
  constructor(public settingsService: SettingsService, private http: HttpClient, private categoryService: CategoriesService,
              private storageService: StorageService) {

  }

  getToken() {
    this.userId = this.storageService.getLocalStore('u_id');
    this.deviceId = this.storageService.getLocalStore('d_id');
    this.guestId = this.storageService.getLocalStore('g_id');
    const aT = this.storageService.getLocalStore('a_t');
    const rT = this.storageService.getLocalStore('r_t');
    return new Promise((resolve, reject) => {
      if (aT && rT) {
        resolve({ data: this.storageService.getLocalStore('a_t'), status_code: 200 });
      } else {
        let params: HttpParams = new HttpParams()
          .set('d_id', this.deviceId);
        if (this.userId) {
          params = params.append('u_id', this.userId);
        } else {
          params = params.append('g_id', this.guestId);
        }
        return this.http.get(environment.apiEndpoint + environment.version + token, { params }).subscribe((response: any) => {
          if (response.status_code === 200) {
            const respData = response.data;
            this.accessToken = respData.access_token;
            this.storageService.setLocalStore('a_t', respData.access_token);
            this.storageService.setLocalStore('r_t', respData.refresh_token);
            this.categoryService.getNextVersionCategories().subscribe();
            resolve(response);
          } else {
            // error_codes 1009,1010
            resolve(response);
          }
        }, (error: HttpErrorResponse) => {
          reject(error);
        });
      }
    });
  }

  getSettings() {
    if (!this.storageService.getLocalStore('d_id') || (!this.storageService.getLocalStore('g_id') && !this.storageService.getLocalStore('u_id'))) {
      window.location.reload();
    }
    return;
  }
}
