import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { search } from 'src/app/shared/constants';
import { catchError, map } from 'rxjs/operators';
import { UikitService } from './uikit.service';
import { environment } from 'src/environment';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(private http: HttpClient, private uikitService: UikitService) { }

  // this service call response for search text content details.
  getData(key, skip, take): Observable<any> {
    const params: HttpParams = new HttpParams()
      .set('keyword', key)
      .set('skip', skip)
      .set('take', take)
      .set('facelift', '1')
      .set('version','v1');
    const searchApiURL = environment.apiEndpoint + environment.version + search;
    return this.http.get(searchApiURL, { params }).pipe(
      map((response: any) => {
        if (response.status_code === 200) {
          return response.data;
        }
        else {
          this.uikitService.notifyError(response);
        }
      }), catchError((error) => {
        if (error?.error?.error?.code !== 1002) {
          this.uikitService.notifyError(error);
        }
        return error;
      })
    );
  }

}
