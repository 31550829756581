import { environment } from 'src/environment';
import { Component, OnInit, Inject, OnDestroy, HostListener } from '@angular/core';
import { BaseService, SettingsService, StorageService } from 'src/app/core/services';
import { Router } from '@angular/router';
import { SearchService } from 'src/app/core/services/search.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfigurationService } from 'src/app/core/services/configuration.service';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';
import { NgClass, NgIf } from '@angular/common';
import { CardListComponent } from '../card-list/card-list.component';
import { SlickItemComponent } from '../slick-item/slick-item.component';
export interface DialogData {
  return;
}

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss', './search.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass, LottieComponent, CardListComponent, SlickItemComponent]
})
export class SearchComponent implements OnInit, OnDestroy {
  slideToShow: number;
  slideToScroll: number;
  slideToShowSeries: number;
  slideToScrollSeries: number;
  windowWidth: number;
  searchKey;
  searchActive: boolean;
  data: any[];
  audioData;
  videoData:any[];
  searchCount;
  noResultsMessage: string;
  audioNoResultMessage:string;
  searchOpenedFirst = true;
  imagePath: any;
  searchName;
  numCardsInRow;
  searchData = [];
  audioSearchData = [];
  searchSlickData = {};
  audioSlickData = {};
  tagsLength;
  showMoreResult = false;
  showLessButton = false;
  showAudioMoreResult = false;
  showAudioLessButton = false;
  options: AnimationOptions = {
    path: `branding/assets/search-loader.json`
  };
  overrideLoader = false;
  takeValue: number;
  paginate = false;
  search_count = 0;
  audio_search_count = 0;
  skip = 0;
  audioSkip = 0;
  tempData = [];
  audioTempData = [];
  totalGetCount = 0;
  totalAudioGetCount = 0;
  dynamicName = '';
  searchSubscriber;
  videoTitle="";
  audioTitle='';
  videoSlickOrientation = 0;
  audioSlickOrientation = 0;
  skinName = environment.skinName;
  purchases: any;
  audioSupported = false;
  all = true;
  AudioSelet = false;
  videos = false;
  selectedTab = ''
  searchOptions = ['All','Videos','Audios'];


  constructor(public settingsService: SettingsService, private router: Router,
    private searchService: SearchService, public dialogRef: MatDialogRef<SearchComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData, public configService: ConfigurationService,
    private baseService: BaseService, private storageService: StorageService) {
    this.takeValue = 12 || this.settingsService.takeValue;
    if(this.settingsService.audioSupported) this.audioSupported = true;
    else this.audioSupported = false;
  }

  ngOnInit(): void {
    this.selectedTab = this.searchOptions[0];
    this.settingsService.showHeader = true;
    this.settingsService.showFooter = false;
    this.purchases = this.storageService.getLocalStore('userPurchases');
    const headerTop = <HTMLInputElement>document.querySelector(".navbar");
    headerTop.style.position = 'fixed';
    headerTop.style.marginRight = '18px';
    this.imagePath = this.settingsService.imagePath;
    this.windowWidth = window.innerWidth;
    this.setNumCards();
    this.searchSubscriber = this.baseService.searchData.subscribe(search => {
      this.searchName = search;
      this.getSearchData(this.searchName, 0, this.takeValue, "searchdata");
    });
    document.getElementById("inputSearch").focus();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setNumCards();
  }

  // this method using divided base on the movie grids
  setNumCards() {
    const windowWidth: number = window.innerWidth;
    this.numCardsInRow = Math.floor((windowWidth - ((169 * 2) - 98)) / (302 + 98));
  }

  // this method using to close the search text
  closeSearch() {
    this.searchActive = false;
    this.data = [];
    this.audioData ={};
    this.searchCount = '';
    this.totalGetCount = 0;
    this.totalAudioGetCount = 0;
    this.dynamicName = '';
    this.searchKey = undefined;
    this.noResultsMessage = '';
    this.audioNoResultMessage = '';
    this.dialogRef.close();
    this.settingsService.showFooter = true;
  }

  // this method for get the search text details
  getSearchData(key, skip, take, type) {
    if(type == 'searchdata'){
      if (this.dynamicName == '') this.dynamicName = key;
      else if (this.dynamicName != key){
        this.totalGetCount = 0;
        this.totalAudioGetCount = 0;
      }
    }
    const headerTop = <HTMLInputElement>document.querySelector(".navbar");
    headerTop.style.zIndex = '1047';
    this.searchOpenedFirst = false;
    this.overrideLoader = true;
    this.paginate = true;
    this.searchService.getData(key, skip, take).subscribe(
      (data) => {
        if (data) {
          this.paginate = false;
          this.data = data.contents;
          this.data.forEach(content =>{
            if(content.key == 'video') {
              this.data[0] = content;
              this.videoTitle = content.title;
              this.videoSlickOrientation = +content.orientation;
              return;
            }
            if(content.key == 'audio') {
              this.audioData = content;
              this.audioTitle = content.title;
              this.audioSlickOrientation = +content.orientation;
              return;
            }
          })
          this.searchCount = data.total;
          this.search_count = this.data[0].list.length;
          this.audio_search_count = this.audioData?.list?.length;
          this.totalGetCount += this.data[0].list.length;
          this.totalAudioGetCount += this.audioData?.list?.length;
          this.overrideLoader = false;
          if (this.search_count > 0) {
            if (this.skip == 0) {
              this.searchData = this.data[0].list;
              Object.assign(this.searchSlickData , {list:this.searchData, orientation:this.videoSlickOrientation, component:'collection_widget'});
            } else {
              this.tempData = this.searchData;
              this.tempData = this.removeDupliactes(this.tempData.concat(this.data[0].list));
              this.searchData = this.tempData;
              Object.assign(this.searchSlickData , {list:this.searchData, orientation:this.videoSlickOrientation, component:'collection_widget'});
            }
          }
          if(this.audio_search_count > 0){
            if(this.audioSkip == 0){
              this.audioSearchData = this.audioData?.list;
              Object.assign(this.audioSlickData , {list:this.audioSearchData, orientation:this.audioSlickOrientation, component:'collection_widget'});
            }else{
              this.audioTempData = this.audioSearchData;
              this.audioTempData = this.removeDupliactes(this.audioTempData.concat(this.audioData?.list));
              this.audioSearchData = this.audioTempData;
              Object.assign(this.audioSlickData , {list:this.audioSearchData, orientation:this.audioSlickOrientation, component:'collection_widget'});
            }
          }
          this.searchData.map(data => {
            if (data.tags && typeof (data.tags) == 'string') {
              this.tagsLength = data.tags.length;
              return data.tags = data.tags.split(',');
            }
          });
          this.audioSearchData.map(data =>{
            if (data.tags && typeof (data.tags) == 'string') {
              this.tagsLength = data.tags.length;
              return data.tags = data.tags.split(',');
            }
          })
          if (this.searchCount === 0 && this.totalGetCount === 0) {
            this.noResultsMessage = 'No search results Found';
          }
          if(this.searchCount === 0 && this.totalAudioGetCount === 0) this.audioNoResultMessage = 'No search results Found';
          if ((this.skip + this.search_count) >= this.searchCount) {
            this.showLessButton = true;
            this.showMoreResult = false;
          } else {
            this.showLessButton = false;
            this.showMoreResult = true;
          }
          if ((this.audioSkip + this.audio_search_count) >= this.searchCount) {
            this.showAudioLessButton = true;
            this.showAudioMoreResult = false;
          } else {
            this.showAudioLessButton = false;
            this.showAudioMoreResult = true;
          }
        }
      }
    );
  }

  getClassForData(data){
    switch (data.component) {
      case 'livechannel_widget':
        return 'post-l-h';
      case 'continue_watching_widget':
        return data.orientation === 2 ? 'post-cw-h' : 'post-cw-h-two';
      case 'my_list_widget':
        return data.orientation === 1 ? 'post-ml-h-two' : 'post-h';
      case 'collection_widget':
      case 'top_picks_for_you_widget':
      case 'because_you_watched_widget':
      case 'my_purchases_widget':
        return data.orientation === 1 ? 'post-col-h-two' : 'post-h';
      case 'group_collection_widget':
      case 'content_partner_widget':
        return 'post-gc-h';
      case 'top_in_region_widget':
        return 'post-reg-h';
      default:
        return 'post-h';
    }
  }

  // this method using to search content details.
  goToSearchPage = function (term) {
    if (term) {
      this.searchService.getData(term).subscribe(
        (data) => {
          if (data) {
            this.data = data.contents;
            this.searchCount = data.total;
            if (this.searchCount === 0) {
              this.noResultsMessage = 'No search results Found';
            }
          }
        }
      );
    }
  };

  // this method using to if click more botton it's display the more related movie grides.
  openMore() {
    this.skip = this.search_count + this.skip;
    this.getSearchData(this.searchName, this.skip, this.takeValue, "loaddata");
  }

  openMoreAudio(){
    this.audioSkip = this.audio_search_count + this.audioSkip;
    this.getSearchData(this.searchName, this.audioSkip, this.takeValue, "loaddata");
  }

  showLess() {
    this.showLessButton = false;
    this.searchData = this.searchData.slice(0, 6);
  }

  ngOnDestroy() {
    if(this.searchSubscriber) this.searchSubscriber.unsubscribe();
    const headerTop = <HTMLInputElement>document.querySelector(".navbar");
    headerTop.style.position = 'fixed';
    headerTop.style.marginRight = '0px';
    headerTop.style.zIndex = '1043';
    this.closeSearch();
  }

  // this method for removing duplicate for content list details 
  removeDupliactes(values) {
    let concatArray = values.map(eachValue => {
      return Object.values(eachValue).join('')
    })
    let filterValues = values.filter((value, index) => {
      return concatArray.indexOf(concatArray[index]) === index
    })
    return filterValues
  }

  selectTab(type){
    this.selectedTab = type;
  }
}
