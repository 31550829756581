import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { StorageService } from './storage.service';
import {
  userPlans, stripeResume, stripeChange, stripeCancel, stripeRedirect, stripeCheckout,
  stripeCouponValidate, applyCoupon, userPayment, stripeCreateSubscription, stripeCompleteSubscription,
  stripeCreateIdealSubscription, stripeCompleteIdealSubscription, stripeStatusSubscription,
  plans, stripeCreatePayment, stripeCompletePayment, stripeIdealPayment, stripeUpdatePayment,
  stripeFingerPrintAPIURL,
  freePlanSubscription,
  checkProratedAmount
} from 'src/app/shared/constants';
import { UikitService } from './uikit.service';
import { SettingsService } from './settings.service';
import { BaseService } from './base.service';
import { DatalayerService } from 'src/app/core/services/datalayer.service';
import { COUPON } from 'src/app/shared/constants/key.constants';
import { environment } from 'src/environment';
import { PlanService } from './plan.service';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  transactionDetails: any;
  planDetails: any;
  subsData;
  oldUser = false;
  userType;
  userDetails; // using this in ideal payment request object to send to api
  adyenPaymentMethods;

  constructor(private http: HttpClient, private storageService: StorageService, private uikitService: UikitService,
    private settingsService: SettingsService, private baseService: BaseService, private datalayerService: DatalayerService, private planService: PlanService
  ) { }

  userPlan(): Observable<any> {
    const discountType: string = this.storageService.getLocalStore('discount_type');
    const discountCode: string = this.storageService.getLocalStore('discount_code');
    const subscriptionUrl = environment.apiEndpoint + environment.version + userPlans;
    let params = new HttpParams()
    if (discountCode && discountType === COUPON) {
      params = params.append('coupon_code', discountCode);
    }
    return this.http.get(subscriptionUrl, { params }).pipe(
      map((response: any) => {
        if (response.status_code === 200) {
          this.subsData = response.data;
          const { one_time_subscription, user_type } = this.subsData;
          if (user_type || one_time_subscription) this.oldUser = true;
          if (response.data?.plans?.length === 1) this.storageService.setSessionStore('selectedPlanData', response.data?.plans[0]);
          return response.data;
        }
        else {
          this.uikitService.notifyError(response);
        }
      }, (error: HttpErrorResponse) => {
        if (error?.error?.error?.code !== 1002) {
          this.uikitService.notifyError(error);
        }
      })
    );
  }

  resumePlanApiFun(data: any): Observable<any> {
    const resumePlanApiFunUrl = environment.apiEndpoint + environment.version + stripeResume;
    return this.http.post(resumePlanApiFunUrl, data).pipe(
      map((response: any) => {
        if (response.status_code === 200) {
          this.uikitService.notifySuccess(response);
          return true;
        }
        else {
          this.uikitService.notifyError(response);
        }
      }, (error: HttpErrorResponse) => {
        if (error?.error?.error?.code !== 1002) {
          this.uikitService.notifyError(error);
        }
      })
    );
  }

  changePlanApiFun(data: any): Observable<any> {
    const changePlanApiFunUrl = environment.apiEndpoint + environment.version + stripeChange;
    return this.http.post(changePlanApiFunUrl, data).pipe(
      map((response: any) => {
        if (response.status_code === 200) {
          this.uikitService.notifySuccess(response);
          return true;
        }
        else {
          this.uikitService.notifyError(response);
        }
      }, (error: HttpErrorResponse) => {
        if (error?.error?.error?.code !== 1002) {
          this.uikitService.notifyError(error);
        }
      })
    );
  }
// this service call for Cancel the subscrption plan
  cancelSubscriptionApiFun(data: any): Observable<any> {
    const cancelSubscriptionUrl = environment.apiEndpoint + environment.version + stripeCancel;
    Object.assign(data, this.settingsService.handleDataMartAnalyticsParamsData());
    return this.http.post(cancelSubscriptionUrl, data).pipe(
      map((response) => {
        this.storageService.removeLocalStore('subsId');
        return response;
      }, (error: HttpErrorResponse) => {
        if (error?.error?.error?.code !== 1002) {
          this.uikitService.notifyError(error);
        }
      })
    );
  }

  saveTransactionDetails(data: any) {
    this.transactionDetails = data;
  }

  getTransactionDetails() {
    return this.transactionDetails;
  }

  updateMethodApi(): Observable<any> {
    const updateMethodUrl = environment.apiEndpoint + environment.version + stripeRedirect;
    const userId: string = this.storageService.getLocalStore('u_id');
    const data = { u_id: userId };
    return this.http.post(updateMethodUrl, data).pipe(
      map((response: any) => {
        if (response.status_code === 200) {
          return response.data;
        }
        else {
          this.uikitService.notifyError(response);
        }
      }, (error: HttpErrorResponse) => {
        if (error?.error?.error?.code !== 1002) {
          this.uikitService.notifyError(error);
        }
      })
    );
  }

  checkOut(data): Observable<any> {
    const checkOutUrl = environment.apiEndpoint + environment.version + stripeCheckout;
    return this.http.post(checkOutUrl, data).pipe(
      map((response: any) => {
        if (response.status_code === 200) {
          return response.data;
        }
        else {
          this.uikitService.notifyError(response);
        }
      }, (error: HttpErrorResponse) => {
        if (error?.error?.error?.code !== 1002) {
          this.uikitService.notifyError(error);
        }
      })
    );
  }

  updateRedirect(data): Observable<any> {
    const updateredirectUrl = environment.apiEndpoint + environment.version + stripeUpdatePayment;
    return this.http.post(updateredirectUrl, data).pipe(
      map((response: any) => {
        if (response.status_code === 200) {
          return response.data;
        }
        else {
          this.uikitService.notifyError(response);
        }
      }, (error: HttpErrorResponse) => {
        if (error?.error?.error?.code !== 1002) {
          this.uikitService.notifyError(error);
        }
      })
    );
  }

  invoiceUserPlan(userPlanData): Observable<any> {
    // userPlanData(plan's subscription_id) we are passing from signup page which is coming from paywall through url queryparams
    const subscriptionUrl = environment.apiEndpoint + environment.version + userPlans + '/' + (userPlanData?.subscriptionId || userPlanData);
    let params = new HttpParams()
    if (userPlanData.fingerprint_checked) {
      params = params.append('fingerprint_checked', 'true')
      params = params.append('stripe_fingerprint', userPlanData.stripe_fingerprint)
    }
    if (userPlanData?.coupon_code) params = params.append('coupon_code', userPlanData?.coupon_code);
    return this.http.get(subscriptionUrl, { params }).pipe(
      map((response: any) => {
        if (response.status_code === 200) {
          this.planDetails = response.data;
          this.storageService.setLocalStore('freeTrialDays', this.planDetails?.plan?.free_trial_days);
          this.planService.freeTrialDays = this.planDetails?.plan?.free_trial_days;
          this.planService.freeTrialText = this.planDetails?.plan?.free_trial_text;
          return response.data;
        }
        else {
          this.uikitService.notifyError(response);
        }
      }, (error: HttpErrorResponse) => {
        if (error?.error?.error?.code !== 1002) {
          this.uikitService.notifyError(error);
          this.baseService.loaderSource.next(false);
        }
      })
    );
  }
// this service call activate voucher code details
  validateCoupon(data) {
    const subscriptionUrl = environment.apiEndpoint + environment.version + stripeCouponValidate;
    Object.assign(data, this.settingsService.handleDataMartAnalyticsParamsData());
    let params = new HttpParams()
     .set('session_id', data.session_id)
     .set('coupon_code', data.coupon_code)
    if (data.fingerprint_checked) {
     params = params.append('fingerprint_checked',data.fingerprint_checked)
     params = params.append('stripe_fingerprint', data.stripe_fingerprint)
    }
    return this.http.post(subscriptionUrl, data, {params}).pipe(
      map((response) => {
        return response;
      }, (error: HttpErrorResponse) => {
        if (error?.error?.error?.code !== 1002) {
          return error;
        }
      })
    );
  }
// this service call activate voucher code details
  applyCoupon(data) {
    const updateMethodUrl = environment.apiEndpoint + environment.version + applyCoupon;
    Object.assign(data, this.settingsService.handleDataMartAnalyticsParamsData());
    return new Promise((resolve, error) => {
      this.http.post(updateMethodUrl, data).subscribe((resp) => {
        resolve(resp);
      }, (err) => {
        resolve(err);
      })
    })
  }

  userPayments(): Observable<any> {
    const userPaymentURL = environment.apiEndpoint + environment.version + userPayment;
    const params = new HttpParams()
    return this.http.get(userPaymentURL, { params }).pipe(
      map((response: any) => {
        if (response.status_code === 200) {
          return response.data;
        }
        else {
          this.uikitService.notifyError(response);
        }
      }), catchError((error) => {
        if (error?.error?.error?.code !== 1002) {
          this.uikitService.notifyError(error);
        }
        return error;
      })
    );
  }

  createSubcription(data): Observable<any> {
    const userPaymentURL = environment.apiEndpoint + environment.version + stripeCreateSubscription;
    Object.assign(data, this.settingsService.handleDataMartAnalyticsParamsData());
    return this.http.post(userPaymentURL, data).pipe(
      map((response: any) => {
        if (response.status_code === 200) {
          this.userType = 1;
          return response.data;
        }
        else {
          this.baseService.loaderSource.next(false);
          this.uikitService.notifyError(response);
        }
      }), catchError((error) => {
        this.baseService.loaderSource.next(false);
        if (error?.error?.error?.code !== 1002) {
          this.uikitService.notifyError(error);
          const datalayerData = {
            event: 'GAEvent',
            eventCategory: 'Payment',
            eventAction: 'Error',
            eventLabel: this.planDetails.plan.plan + ' - ' + this.planDetails.plan.amount,
            loginStatus: (this.storageService.getLocalStore('u_id') ? 'True' : 'False'),
            userId: (this.storageService.getLocalStore('u_id') ? this.storageService.getLocalStore('u_id') : ''),
            notificationStatus: localStorage.getItem('emailNotify') ||  'False',
            region: (this.storageService.getLocalStore('region') ? this.storageService.getLocalStore('region') : ''),
            platform: this.baseService.browserType,
            userType: this.storageService.getLocalStore('user_type'),
            operator: this.storageService.getLocalStore('operator_name')
          };
          this.datalayerService.addDatalayer(datalayerData);
        }
        this.storageService.removeLocalStore('discount_type');
        this.storageService.removeLocalStore('discount_code');
        this.storageService.removeLocalStore('operator_voucher');
        return error;
      })
    );
  }

  completeSubcription(data): Observable<any> {
    const userPaymentURL = environment.apiEndpoint + environment.version + stripeCompleteSubscription;
    return this.http.post(userPaymentURL, data).pipe(
      map((response: any) => {
        if (response.status_code === 200) {
          return response.data;
        }
        else {
          this.uikitService.notifyError(response);
        }
      }), catchError((error) => {
        if (error?.error?.error?.code !== 1002) {
          this.uikitService.notifyError(error);
        }
        return error;
      })
    );
  }

  createIdealSubcription(data): Observable<any> {
    const userIdealPaymentURL = environment.apiEndpoint + environment.version + stripeCreateIdealSubscription;
    return this.http.post(userIdealPaymentURL, data).pipe(
      map((response: any) => {
        if (response.status_code === 200) {
          this.userType = 1;
          return response.data;
        }
        else {
          this.uikitService.notifyError(response);
        }
      }), catchError((error) => {
        if (error?.error?.error?.code !== 1002) {
          this.uikitService.notifyError(error);
        }
        return error;
      })
    );
  }

  completeIdealSubcription(data): Observable<any> {
    const userId = this.storageService.getLocalStore('u_id');
    const userIdealPaymentURL = environment.apiEndpoint + environment.version + stripeCompleteIdealSubscription;
    const body = {
      u_id: userId, subscription_id: data.subscriptionId,
      stripe_subscription_id: data.stripeSubscriptionId
    };
    return this.http.post(userIdealPaymentURL, body).pipe(
      map((response: any) => {
        if (response.status_code === 200) {
          this.userType = 1;
          return response.data;
        }
        else {
          this.uikitService.notifyError(response);
        }
      }), catchError((error) => {
        if (error?.error?.error?.code !== 1002) {
          this.uikitService.notifyError(error);
        }
        return error;
      })
    );
  }

  subscriptionStatus(data): Observable<any> {
    const userId = this.storageService.getLocalStore('u_id');
    const stripeStatusURL = environment.apiEndpoint + environment.version + stripeStatusSubscription;
    const body = {
      u_id: userId, subscription_id: data.subscriptionId,
      stripe_subscription_id: data.stripeSubscriptionId
    };
    return this.http.post(stripeStatusURL, body).pipe(
      map((response: any) => {
        if (response.status_code === 200) {
          return response.data;
        }
        else {
          this.uikitService.notifyError(response);
        }
      }), catchError((error) => {
        if (error?.error?.error?.code !== 1002) {
          this.uikitService.notifyError(error);
        }
        return error;
      })
    );
  }

  createPayment(body): Observable<any> {
    const createPaymentURL = environment.apiEndpoint + environment.version + stripeCreatePayment;
    return this.http.post(createPaymentURL, body).pipe(
      map((response: any) => {
        if (response.status_code === 200) {
          return response.data;
        }
        else {
          this.uikitService.notifyError(response);
        }
      }), catchError((error) => {
        if (error?.error?.error?.code !== 1002) {
          this.uikitService.notifyError(error);
        }
        return error;
      })
    );
  }

  completePayment(data): Observable<any> {
    const completePaymentURL = environment.apiEndpoint + environment.version + stripeCompletePayment;
    Object.assign(data, this.settingsService.handleDataMartAnalyticsParamsData());
    return this.http.post(completePaymentURL, data).pipe(
      map((response: any) => {
        if (response.status_code === 200) {
          return response.data;
        }
        else {
          this.uikitService.notifyError(response);
        }
      }), catchError((error) => {
        if (error?.error?.error?.code !== 1002) {
          this.uikitService.notifyError(error);
        }
        return error;
      })
    );
  }

  idealPayment(body): Observable<any> {
    const stripeIdealPaymentURL = environment.apiEndpoint + environment.version + stripeIdealPayment;
    return this.http.post(stripeIdealPaymentURL, body).pipe(
      map((response: any) => {
        if (response.status_code === 200) {
          return response.data;
        }
        else {
          this.uikitService.notifyError(response);
        }
      }), catchError((error) => {
        if (error?.error?.error?.code !== 1002) {
          this.uikitService.notifyError(error);
        }
        return error;
      })
    );
  }

  fingerPrintCheck(body): Observable<any> {
    const stripeIdealPaymentURL = environment.apiEndpoint + environment.version + stripeFingerPrintAPIURL;
    return this.http.post(stripeIdealPaymentURL, body).pipe(
      map((response: any) => {
        if (response.status_code === 200) {
          return response.data;
        }
        else {
          this.baseService.loaderSource.next(false);
          this.uikitService.notifyError(response);
        }
      }), catchError((error) => {
        this.baseService.loaderSource.next(false);
        if (error?.error?.error?.code !== 1002) {
          this.uikitService.notifyError(error);
        }
        return error;
      })
    );
  }

  userPlanCheck() {
    const userId: string = this.storageService.getLocalStore('u_id');
    const subscriptionUrl = environment.apiEndpoint + environment.version + userPlans;
    let params = new HttpParams()
      .set('u_id', userId);
    return this.http.get(subscriptionUrl, { params }).toPromise();
  }

  // check prorated amount api
  getProratedAmount(requestData) {
    const proratedAmountApiUrl = environment.apiEndpoint + environment.version + checkProratedAmount;
    return this.http.post(proratedAmountApiUrl, requestData).pipe(map((response: any) => {
        if (response.status_code === 200) {
          return response.data;
        }
        else {
          this.baseService.loaderSource.next(false);
          this.uikitService.notifyError(response);
        }
      }), catchError((error) => {
        this.baseService.loaderSource.next(false);
        if (error.error && error.error.code !== 1002) {
          this.uikitService.notifyError(error);
        }
        return error;
    }));
  }

  getFreePlanSubscription(reqObj) {
    const freePlanUrl = environment.apiEndpoint + environment.version + freePlanSubscription;
    return this.http.post(freePlanUrl, reqObj).pipe(map((response: any) => {
      if (response.status_code === 200) {
        this.userType = 1;
        return response.data;
      }
      else {
        this.baseService.loaderSource.next(false);
        this.uikitService.notifyError(response);
      }
    }), catchError((error) => {
      this.baseService.loaderSource.next(false);
      if (error.error && error.error.code !== 1002) {
        this.uikitService.notifyError(error);
      }
      return error;
    }));
  }

  completeAdyenIdealPayment(queryParams) {
    const locale = this.storageService.getLocalStore('locale');
    const region = this.storageService.getLocalStore('region');
    const u_id = this.storageService.getLocalStore('u_id');
    let reqData: any = { locale, region, u_id};
    reqData.session_id = localStorage.getItem('sessionId');
    reqData = { ...reqData, details: queryParams};
    this.completeSubcription(reqData)
    .subscribe((resp) => {
      if (resp.status_code === 200) {
        // this.toptext = this.configService.localeKeys?.["plans.payment_successful_title.text"];
        // this.bottomText = resp.message;
        // setTimeout(() => this.openUikitDialog('#ideal_pay_redirect_model'), 100);
      }
    });
  }

}
