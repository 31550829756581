import { environment } from 'src/environment';
import { Component, HostListener, Input, OnInit } from '@angular/core';
import { FooterService } from '../../services/footer.service';

@Component({
  selector: 'app-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss']
})
export class LinkComponent implements OnInit {
  
  @Input() footerKey:string;
  @Input() footerNavText:string;
  footerSlug: string;
  innerWidth;

  skinName = environment.skinName;
  constructor(private footerService:FooterService) { }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  ngOnInit(): void {
    const pageList = this.footerService.pageList || [];
    this.innerWidth = window.innerWidth;
    pageList.forEach((page: any) => {
      if (page.type === this.footerKey) {
        this.footerSlug = page.slug;
      }
    });
  }

}
