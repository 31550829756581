// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import config from './projectConfig.json';

export const environment = {
  production: true,
  apiEndpoint: config.apiEndpoint,
  socialUrl: config.apiEndpoint,
  version: 'v2/',
  v3_version: 'v3/',
  routeUrl: config.webRouteUrl,
  cookieDomain: config.cookieDomain,
  paywallURL: config.paywallRouteUrl,
  ezProxyUrl: config.ezProxyUrl,
  isEzProxy: config.isEzProxy,
  apiAppName: config.apiAppName,
  appVersion: config.storeVersion,
  skinName: config.skinName,
  deviceType: config.deviceType
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
