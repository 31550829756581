import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { UserGuard } from '../core/gaurds/user.guard';
import { TokenResolverService } from '../core/services/token-resolver.service';
import { LoginGuard } from '../core/gaurds/login.gaurd';
import { OperatorPaymentGuard } from '../core/gaurds/operator-payment.gaurd';
import { ChurnGuard } from '../core/gaurds/churn.gaurd';
import { LoginModeGuard } from '../core/gaurds/login-mode.guard';
import { SvodFlagGuard } from '../core/gaurds/svod-flag.guard';
import { EzproxyGuard } from '../core/gaurds/ezproxy.guard';
import { HooplaGuard } from '../core/gaurds/hoopla.guard';
import { HooplaComponent } from './hoopla/hoopla.component';


const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'home' },
  { path: ':path**/home', pathMatch: 'full', redirectTo: 'home' },
  {
    path: '404',
    loadChildren: () => import('./not-found/not-found.module').then(m => m.NotFoundModule),
  },
  {
    path: 'maintenance',
    loadChildren: () => import('./not-found/not-found.module').then(m => m.NotFoundModule),
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
    resolve: {
      data: TokenResolverService
    },
    canActivate: [LoginModeGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./login-method/login-method.module').then(m => m.LoginMethodModule),
    resolve: {
      data: TokenResolverService
    },
    canActivate: [LoginGuard, EzproxyGuard, HooplaGuard],
  },
  {
    path: 'auth',
    loadChildren: () => import('./user/user.module').then(m => m.UserModule),
    resolve: {
      data: TokenResolverService
    },
  },
  {
    path: 'payment',
    loadChildren: () => import('./payment/payment.module').then(m => m.PaymentModule),
    canActivate: [ EzproxyGuard, HooplaGuard ]
  },
  {
    path: 'subscription',
    loadChildren: () => import('./subscribe/subscribe.module').then(m => m.SubscribeModule),
    canActivate: [SvodFlagGuard, EzproxyGuard, HooplaGuard]
  },
  {
    path: 'account',
    loadChildren: () => import('./account/account.module').then(m => m.AccountModule),
    resolve: {
      data: TokenResolverService
    },
    canActivate: [ EzproxyGuard, HooplaGuard],
  },
  {
    path: 'index',
    loadChildren: () => import('./start-app/start-app.module').then(m => m.StartAppModule),
    resolve: {
      data: TokenResolverService
    }
  },
  {
    path: 'app',
    loadChildren: () => import('./general/general.module').then(m => m.GeneralModule),
    resolve: {
      data: TokenResolverService
    },
  },
  {
    path: 'voucher',
    loadChildren: () => import('./voucher/voucher.module').then(m => m.VoucherModule),
    canActivate: [OperatorPaymentGuard, SvodFlagGuard],
    resolve: {
      data: TokenResolverService
    },
  },
  {
    path: 'tv',
    loadChildren: () => import('./tv/tv.module').then(m => m.TvModule),
    resolve: {
      data: TokenResolverService
    },
  },
  {
    path: 'smart-tv', // we need this route for QR code navigation from tv and metro
    loadChildren: () => import('./appletv/appletv.module').then(m => m.AppletvModule),
    resolve: {
      data: TokenResolverService
    },
  },
  {
    path: 'not-available',
    loadChildren: () => import('./not-available/not-available.module').then(m => m.NotAvailableModule),
  },
  {
    path:'churn',
    loadChildren:()=> import('./churn/churn.module').then(m=>m.ChurnModule),
    canActivate: [LoginModeGuard, UserGuard, ChurnGuard]
  },
  {
    path: 'hoopla/:id',
    component: HooplaComponent, 
    resolve: {
      data: TokenResolverService
    }
  },
  {
    path: 'snappet/:id',
    component: HooplaComponent, 
    resolve: {
      data: TokenResolverService
    }
  },
  {
    path: '',
    loadChildren: () => import('./content/content.module').then(m => m.ContentModule),
    resolve: {
      data: TokenResolverService
    },
    canActivate: [LoginModeGuard]
  },
  // { path: '**', redirectTo: 'coming-soon' },
  // { path: '**', pathMatch: 'full', redirectTo: 'coming-soon' }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PublicRoutingModule { }

