<div [class]="skinName">
  @if (footerSlug && footerNavText.length >= 20 && innerWidth <= 375) {
    <span [routerLink]="['/app/page', footerSlug]" id="footerLinkText" class="cursor-pointer" [innerText]="footerNavText.substring(0, 20) + '...'"></span>
  }
  @if (footerSlug && footerNavText.length < 20 && innerWidth <= 375) {
    <span [routerLink]="['/app/page', footerSlug]" id="footerLinkText" class="cursor-pointer">{{footerNavText}}</span>
  }
  @if (footerSlug && innerWidth > 375) {
    <span [routerLink]="['/app/page', footerSlug]" id="footerLinkText" class="cursor-pointer">{{footerNavText}}</span>
  }

</div>