<div [class]="skinName">
  <footer id="footer-element">
    <div id="footer-container">
      <div class="w-100 d-flex list-align">
        <div class="footer-list">
          <div class="browse">
            <div class="head mb-12"><strong>{{configService.localeKeys['kw_browse'] || 'Browse'}}</strong></div>
            @for (value of categoryService.categories | async; track value) {
              <div>
                <div class="list cursor-pointer" (click)="redirect(value.slug)">{{value?.title}} </div>
              </div>
            }
          </div>
          @if ( (settingsService.feedbackEnabled || settingsService.whereToWatch ||footercontents['faq']  || footercontents['contact'])) {
            <div class="help">
              <div class="head mb-12">
                @if (configService.localeKeys['help_center.help.text'].length >= 12 && innerWidth <= 400) {
                  <strong>{{configService.localeKeys['help_center.help.text'].substring(0, 15) || 'Help'}}...</strong>
                }
                @if (configService.localeKeys['help_center.help.text'].length <= 12 && innerWidth <= 400) {
                  <strong>{{configService.localeKeys['help_center.help.text'] || 'Help'}}</strong>
                }
                @if (innerWidth > 400) {
                  <strong>{{configService.localeKeys['help_center.help.text'] || 'Help'}}</strong>
                }
              </div>
              <div class="list">
                <app-link [footerKey]="'faq'" [footerNavText]="configService.localeKeys['common_keys.faq.text'] || 'FAQ'"></app-link>
              </div>
              @if (settingsService.feedbackEnabled) {
                <div class="list cursor-pointer" (click)="feedbackRedirect()">{{configService.localeKeys['feedback.feedback.text'] || 'FeedBack'}} </div>
              }
              <div class="list">
                <app-link [footerKey]="'contact'" [footerNavText]="configService.localeKeys['common_keys.contact_us.btn'] || 'Contact Us'"></app-link>
              </div>
              @if (settingsService.whereToWatch) {
                <div class="list cursor-pointer" [routerLink]="['/app/where-to-watch']">{{configService.localeKeys['where_to_watch.where_to_watch.text']}} </div>
              }
            </div>
          }
          @if (footercontents['terms']  || footercontents['privacy'] ||footercontents['about'] ) {
            <div class="info">
              <div class="head mb-12">
                @if (configService.localeKeys['kw_information'].length >= 12 && innerWidth <= 400) {
                  <strong>{{configService.localeKeys['kw_information'].substring(0, 15) || 'Information'}} ...</strong>
                }
                @if (configService.localeKeys['kw_information'].length <= 12 && innerWidth <= 400) {
                  <strong>{{configService.localeKeys['kw_information'] || 'Information'}}</strong>
                }
                @if (innerWidth > 400) {
                  <strong>{{configService.localeKeys['kw_information'] || 'Information'}}</strong>
                }</div>
                <div class="list">
                  <app-link [footerKey]="'terms'" [footerNavText]="configService.localeKeys?.['common_keys.terms_conditions.text']"></app-link>
                </div>
                <div class="list">
                  <app-link [footerKey]="'privacy'" [footerNavText]="configService.localeKeys['common_keys.privacy_policy.text'] || 'Privacy Policy'"></app-link>
                </div>
                <div class="list">
                  <app-link [footerKey]="'about'" [footerNavText]="configService.localeKeys['common_keys.about_us.text']|| 'About Us'"></app-link>
                </div>
                <div class="list">
                  <!-- OneTrust Cookies Settings button start -->
                  <!-- <button id="ot-sdk-btn" class="ot-sdk-show-settings">Cookie Settings</button> -->
                  <!-- OneTrust Cookies Settings button end -->
                </div>
              </div>
            }
          </div>
          @if (showLanguageTop) {
            <ul id="lang-dropdown">
              @if (localeLength>1) {
                <li id="select-text" class="dropdown nav-profile">
                  <a id="lang-text-align" class="dropdown-toggle" data-toggle="dropdown">
                    <span id="select-lang-text">{{configService.activeLocale || selectedLocale}}</span>
                    <img class="langdown-img" src="branding/theme/assets/static_assets/ic-down.svg">
                  </a>
                  <div id="dropdown-list" class="dropdown-menu" [ngClass]="dropPosBottom ? 'dropdown-items': 'dropdown-items-sec'"  role="menu">
                    <ul class="border-bot more-profile lang-align">
                      @for (value of locales; track value; let index = $index) {
                        <li>
                          <a role="menuitem" (click)="setLocale(index,value)" tabindex="-1">
                          {{value.translatedLanguage}}</a>
                        </li>
                      }
                    </ul>
                  </div>
                </li>
              }
            </ul>
          }
        </div>
        @if ((settingsService.appstore || settingsService.playstore) && !restrictedUser) {
          <div class="w-100" id="download-text"><strong>{{configService.localeKeys['common_keys.download.btn']}}</strong></div>
        }
        <!-- <div class="w-100" id="download-text" *ngIf="!restrictedUser"><strong>{{configService.localeKeys['common_keys.download.btn']}}</strong></div> commented this as it need for reading corner -->
        <div class="footer-icon">
          @if ((settingsService.appstore || settingsService.playstore) && !restrictedUser) {
            <div class="store-icon">
              @if (settingsService.appstore) {
                <a target="_blank" [attr.href]="settingsService.appstore"><img class="play-store-img" src="branding/theme/assets/static_assets/ic-app-store.svg" alt=""></a>
              }
              @if (settingsService.playstore) {
                <a target="_blank" [attr.href]="settingsService.playstore"><img class="play-store-img" src="branding/theme/assets/static_assets/ic-google-play.svg" alt=""></a>
              }
            </div>
          }
          @if (!showLanguageTop) {
            <ul id="lang-dropdown">
              @if (localeLength>1) {
                <li id="select-text" class="dropdown nav-profile">
                  <a id="lang-text-align" class="dropdown-toggle" data-toggle="dropdown">
                    <span id="select-lang-text">{{configService.activeLocale || selectedLocale}}</span>
                    <img class="langdown-img" src="branding/theme/assets/static_assets/ic-down.svg">
                  </a>
                  <div id="dropdown-list" class="dropdown-menu"  [ngClass]="dropPosBottom ? 'dropdown-items': 'dropdown-items-sec'" role="menu">
                    <ul class="border-bot more-profile lang-align">
                      @for (value of locales; track value; let index = $index) {
                        <li>
                          <a role="menuitem" (click)="setLocale(index,value)" tabindex="-1">
                          {{value.translatedLanguage}}</a>
                        </li>
                      }
                    </ul>
                  </div>
                </li>
              }
            </ul>
          }
          @if (settingsService.socialAvail) {
            <div class="social-icon">
              @if (settingsService.socialLink.facebook) {
                <a target="_blank" [attr.href]="settingsService.socialLink.facebook" class="mr-3"><img src="branding/theme/assets/static_assets/ic-facebook.svg" alt=""></a>
              }
              <!-- @if (settingsService.socialLink.instagram) {
                <a target="_blank" [attr.href]="settingsService.socialLink.instagram" class="mr-3"><img src="branding/theme/assets/static_assets/footer_instagram.svg" alt=""></a>
              } -->
              <!-- <a *ngIf="settingsService.socialLink.twitter" target="_blank" [attr.href]="settingsService.socialLink.twitter" class="mr-3"><img src="branding/theme/assets/static_assets/footer_twitter.svg" alt=""></a> -->
            </div>
          }
        </div>
      </div>
    </footer>

  </div>