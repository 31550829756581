<div class="audio-playlist-container" [class.to-centre]="queData.length == 0">
    @if(queueExpanded){
        <div class="queue-breadcrums">
            <span (click)="shrinkViewall()" class="queue-breadcrums-padding breadcrums-queue-play">Player</span>
            <span class="queue-breadcrums-padding">></span>
            <span class="queue-breadcrums-padding breadcrums-queue-clr">Queue</span>
        </div>
        <div class="queue-current-play">
            <span>Current Playing</span>
        </div>
    }
    <div class="audio-playlist-top" [class.audio-playlist-top-expand]="queueExpanded">
        <img class="audio-playlist-img" [src]="settingsService.getImage(currentPlayingContent?.poster_square, 'card')">
        <div class="details-container">
            <span class="title" [class.title-expand]="queueExpanded">{{currentPlayingContent.title}}</span>
            @if(currentPlayingType == 'audio'){
                <span class="casting" [class.casting-expand]="queueExpanded">{{currentPlayingContent.cast}}</span>
            }
            @if(currentPlayingType == 'podcast'){
                <div class="tags">
                    @for(item of currentPlayingContent.genre; track item){
                        <span>{{item}}</span>
                        @if(!$last){
                            <span> | </span>
                        }
                    }
                </div>
            }
            @if(!queueExpanded){
                <span class="wishlist-btn" (click)="addToWishlist()">
                    <img class="mylist-img" src="branding/theme/assets/static_assets/ic-aud-pod-empty-heart-on-dbg.svg">
                    <span class="mylist-text">{{"Add to List"}}</span>
                </span>
            }
        </div>
    </div>
    @if(queData.length != 0){
        <div class="audio-playlist-queue-container" [class.audio-playlist-queue-container-expand]="queueExpanded">
            @if(currentPlayingType == 'audio'){
                <div class="queue-title-header">
                    @if(!queueExpanded){
                        <div class="queue-title">{{"Queue"}}</div>
                    }
                    @if(queueExpanded){
                        <div class="queue-title">{{"Upcoming"}}</div>
                    }
                    @if( !queueExpanded && (queData.length > hideQueueLength)){
                        <div class="queue-title queue-viewall" (click)="expandViewall()">{{"View all"}}</div>
                    }
                </div>
                <div class="queue-detail-container">
                    @for(content of queData;track content){
                        @if($index  < hideQueueContent ){
                            <div class="queue">
                                <div class="queue-detail">
                                    <img class="playing-img" [src]="settingsService.getImage(content?.poster_square, 'card')">
                                    <div class="playing-detail">
                                        <span class="queue-title-inside">{{content?.title}}</span>
                                        <span class="queue-casting">{{content?.cast}}</span>
                                    </div>
                                </div>
                                <div class="queue-btn-section">
                                    <div class="round-container">
                                        <img class="playlist-like-icon"  src="branding/theme/assets/static_assets/ic-aud-pod-empty-heart-on-dbg.svg">
                                    </div>
                                    <img class="playlist-play-icon" (click)="audioPlayInit($index)" src="branding/theme/assets/static_assets/ic-aud-pod-play-on-dbg.svg">
                                </div>
                            </div>
                        }
                    }
                </div>
            }
            @if(currentPlayingType == 'podcast'){
                <div class="queue-podacst-header">
                    <div class="queue-podcast-header-title" [class.selected-tile]="selectedType == 'episode'" (click)="changeType('episode')">{{"Episodes"}}</div>
                    <div class="queue-podcast-header-title" [class.selected-tile]="selectedType == 'about'" (click)="changeType('about')">{{"About"}}</div>
                </div>
                <div class="queue-detail-container">
                @if(selectedType == 'episode'){
                    @for(content of queData;track content){
                        <div class="queue-container">
                            <div class="queue-podcast">
                                <div class="queue-detail">
                                    <img class="playing-img" [src]="settingsService.getImage(content?.poster_square, 'card')">
                                    <div class="playing-detail">
                                        <span class="queue-title-inside">{{content?.title}}</span>
                                        <div class="date-detail">
                                            <span class="queue-date-time">{{content?.publish | queueListDate}} | </span>
                                            <span class="queue-date-time">{{content?.duration_sec | queueListDuration}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="queue-btn-section">
                                    @if(!isPlaying || (currentPlayingEpisodeIndex == $index)){
                                        <img (click)="podcastPlayInit(content?.position)" class="playlist-play-icon" src="branding/theme/assets/static_assets/ic-aud-pod-play-on-dbg.svg">
                                    }@else if( isPlaying && (currentPlayingEpisodeIndex == $index)){
                                        <img (click)="podcastPlayPause(content?.position)" class="playlist-play-icon" src="branding/theme/assets/static_assets/ic-aud-pod-pause-on-dbg.svg">
                                    }
                                </div>
                            </div>
                            <div class="queue-description">{{content?.description}}</div>
                            <div class="horz-seperator"></div>
                        </div>
                    }
                }
                @if(selectedType == 'about'){
                    <div class="queue-about">
                    </div>
                }
                </div>
            }
        </div>
    }
</div>
