import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'queueListDuration',
  standalone: true
})
export class QueueListDurationPipe implements PipeTransform {

  transform(data){
    const hours = Math.floor(data/3600);
    data %= 3600;
    const mins = Math.floor(data/60);
    const secs = data % 60;
    return `${String(hours).padStart(2, '0')}:${String(mins).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
  }

}
