import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BaseService, SettingsService, StorageService } from '../services';

@Injectable({
    providedIn: 'root'
})
export class ChurnGuard  {

    memoryStorage;
    guest;

    constructor(private router: Router, private settingsService: SettingsService,
                ) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const churnFlag = this.settingsService.churnPreventionFlowEnabled;
        if (churnFlag) {
            return true;
        } else {
            this.router.navigateByUrl('/home');
            return false;
        }
    }
}
