<div [class]="skinName">
    <div class="alert-box-dialog notify-box">
        <div class="notify-logo"><img [src]="settingsService.imagePath + 'notify_logo.png'"></div>
        <div class="notify-header">
            <p class="title">{{dialogData.title}}</p>
            <div class="button-alignment">
                <button (click)="dialogRef.close()" class="accept-btn">{{dialogData.btnText}}</button>
            </div>
        </div>
    </div>

</div>