import { AfterViewInit, Component, ElementRef, HostListener, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { BaseService, SettingsService } from 'src/app/core/services';
import { ContentService } from 'src/app/core/services/content.service';
import { AudioPlaylistComponent } from '../audio-playlist/audio-playlist.component';
import * as $ from 'jquery';
import { PlayContentService } from 'src/app/core/services/play-content.service';
import { SubCategoryService } from 'src/app/core/services/sub-category.service';
import { NgClass } from '@angular/common';
import { CategoriesService } from 'src/app/core/services/categories.service';
import { QueueListDatePipe } from '../../pipes/queue-list-date.pipe';
import { QueueListDurationPipe } from '../../pipes/queue-list-duration.pipe';
import { UikitService } from 'src/app/core/services/uikit.service';

@Component({
  selector: 'app-audio-podcast',
  standalone: true,
  imports: [AudioPlaylistComponent, NgClass, QueueListDatePipe,QueueListDurationPipe],
  templateUrl: './audio-podcast.component.html',
  styleUrl: './audio-podcast.component.scss'
})
export class AudioPodcastComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() data;
  @ViewChild('audioPlayer') audioPlayer!: ElementRef<HTMLAudioElement>;
  @ViewChild('progressBarContainer') progressBarContainer!: ElementRef<HTMLDivElement>;
  @ViewChild('progressBar') progressBar!: ElementRef<HTMLDivElement>;
  @ViewChild('audioPodcastMobileContainer') mobileAudioPodcastContainer:ElementRef<HTMLDivElement>;
  currentPlayingContentUrl = "";
  showPlayer = false;
  currentContentPersona;
  currentContentAssetData:{title:string, cast:string, poster_square:string, genre:Array<any>, content_id, is_audio, is_series,description};
  mobScreenCurrentEpisodeTitle:string;
  currentPlayingEpisode;
  currentProgressPercentage;
  isPlaying = false;
  position;
  guest;
  currentCollection;
  playlistContent =[];
  queueList= [];
  shuffle=false;
  audioRepeat = false;
  genreCurationEnabled;
  episodesList = [];
  currentSelectedType='';
  queueListOpen = false;
  guestSubscribe;
  selectedContentTypeSubscribe;
  currentContentCollectionSubscribe;
  currentPlayingTime;
  totalPlayingTime;
  personaApiFail = false;
  audioCollectionApiFail = false;
  windowWidth;
  showMobScreen = false;
  showPodcastMiniMobPlayer = false;
  maxLengthToHide = 5;
  mobileViewallExpand = false;
  mobScreenEpisodePlayTrigger = false;
  queuelistChoosen = 'episode';
  currentPlayingEpisodeIndex;
  closeMiniPlayerSubscribe;
  volumeIcon: string = 'branding/theme/assets/static_assets/ic-aud-pod-vol-high-on-dbg.svg';
  mutedVolumeValue;


  constructor(private contentService:ContentService, public settingsService:SettingsService,
    private playContentService:PlayContentService,private categoriesService: CategoriesService,
    private baseService:BaseService, private subCategoryService:SubCategoryService, private uikitService:UikitService ){
    this.genreCurationEnabled = this.settingsService.genreCurationEnabled;
    this.guestSubscribe =  this.baseService.guest.subscribe(data => this.guest = data);
    this.selectedContentTypeSubscribe = this.playContentService.selectedContentType.subscribe(data => this.currentSelectedType = data)
    this.currentContentCollectionSubscribe = this.playContentService.currentContentCollection.subscribe(data =>{
      this.currentCollection = data;
      this.position = this.currentCollection?.position;
    })
    this.closeMiniPlayerSubscribe = this.playContentService.closeMiniPlayer.subscribe(data => data && this.closePlayer());
    this.windowWidth = window.innerWidth;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.playlistContent.length){
      this.playlistContent = [];
      this.queueList = [];
      this.episodesList = [];
    }
    if(changes.data.currentValue){
      this.currentContentAssetData={
        title:this.data?.title || "",
        cast:this.data?.cast || "",
        poster_square:this.data?.poster_square || "",
        genre:[],
        content_id:this.data?.content_id,
        is_audio:this.data?.is_audio,
        is_series:this.data?.is_series,
        description:''
      }
      if((this.data?.is_audio && !this.data?.is_series)) {
        this.getContentPersona(this.data?.content_slug);
        this.showPlayer = true;
      }
      if((this.data?.is_audio && this.data?.is_series)) {
        this.getAllEpisode(this.data?.series_slug);
        this.showPlayer = false;
        if(this.windowWidth <= 1024) {
          this.showPlayer = true;
          this.showPodcastMiniMobPlayer = false;
          this.showMobScreen = true;
        }
       if(this.windowWidth > 1024) this.openUikitDialog('#audio-playlist');
      }
      if(this.currentCollection?.component != 'audio_wishlist'){
        if((this.data?.is_audio && !this.data?.is_series)) this.getAllContents();
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(event: any) {
    this.windowWidth = window.innerWidth;
    console.log(this.windowWidth)
  }

  onTouchStart(event: TouchEvent){
    this.showMobScreen = true;
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    let audioPlayer = this.audioPlayer.nativeElement
    audioPlayer.addEventListener('timeupdate', () => {
      this.updateCurrentProgressbar();
    });
    audioPlayer.addEventListener('loadedmetadata', () => {
      this.updateTotalDuration();
    });
    audioPlayer.addEventListener('volumechange', () => {
      this.updateVolume()
    })
    audioPlayer.addEventListener('ended',() =>{
      this.onAudioEnded();
    });
    audioPlayer.addEventListener('error',(event) =>{
      this.onAudioError(event);
    });
    audioPlayer.addEventListener('play',()=>{
      this.onPlayerPlay()
    });
    audioPlayer.addEventListener('pause',()=>{
      this.onPlayerPause()
    });
    this.progressBarContainer.nativeElement.addEventListener('click', this.seekTo.bind(this)); // Add click listener
    this.updateVolumeIcon(this.audioPlayer.nativeElement.volume);
  }

  setVolume(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    this.audioPlayer.nativeElement.volume = inputElement.valueAsNumber;
    this.updateVolumeIcon(inputElement.valueAsNumber);
  }

  updateVolume(): void {
    if(this.windowWidth > 1024){
      const currentVolume = this.audioPlayer.nativeElement.volume;
      let volumeSlider = document.querySelector('.volume-slider') as HTMLInputElement;
      volumeSlider.value = currentVolume.toString();
      this.updateVolumeIcon(currentVolume);
    }
  }

  updateVolumeIcon(volume: number): void {
    if (volume === 0) {
      this.volumeIcon = 'branding/theme/assets/static_assets/ic-aud-pod-vol-0-on-dbg.svg';
    } else if (volume > 0 && volume <= 0.3) {
      this.volumeIcon = 'branding/theme/assets/static_assets/ic-aud-pod-vol-low-on-dbg.svg';
    } else if (volume > 0.3 && volume <= 0.7) {
      this.volumeIcon = 'branding/theme/assets/static_assets/ic-aud-pod-vol-med-on-dbg.svg';
    } else {
      this.volumeIcon = 'branding/theme/assets/static_assets/ic-aud-pod-vol-high-on-dbg.svg';
    }
  }

  muteUnmute(){
    console.log("clicked")
    let currentVolume = this.audioPlayer.nativeElement.volume;
    if(currentVolume != 0){
      this.mutedVolumeValue = this.audioPlayer.nativeElement.volume;
      this.audioPlayer.nativeElement.volume = 0;
    }else if(this.mutedVolumeValue) this.audioPlayer.nativeElement.volume = this.mutedVolumeValue;
  }

  seekTo(event: MouseEvent) {
    const progressBarRect = this.progressBarContainer.nativeElement.getBoundingClientRect();
    const clickPosition = event.clientX - progressBarRect.left;
    const seekTime = (clickPosition / progressBarRect.width) * this.audioPlayer.nativeElement.duration;
    this.audioPlayer.nativeElement.currentTime = seekTime;
    this.updateCurrentProgressbar(); // Update the progress bar after seeking
  }

  getContentPersona(slug){
    if(this.playlistContent.length > 0){
      let findObj = this.playlistContent.find(content => {
        content.content_slug == slug && content.playbackurl !== ''
      })
      if(findObj){
        this.currentPlayingContentUrl = findObj.playbackurl;
        this.auidoPlayerTrigger();
      } else  this.personaApiCall(slug)
    }else{
      this.personaApiCall(slug)
    }

  }

  scrollDown(){
    if(this.currentSelectedType == 'audio'){
      if(this.showMobScreen) this.showMobScreen = false;
    }
    if(this.currentSelectedType == 'podcast'){
      if(this.showPodcastMiniMobPlayer) this.showMobScreen = false;
      else this.closePlayer()
    }
  }

  goBack(){
    this.mobileViewallExpand = false;
  }

  addToWishlist(){
    this.playContentService.addToWishlist(this.currentContentAssetData);
  }

  personaApiCall(slug){
    this.contentService.getContentPersonData(slug).subscribe((resp) =>{
      if(resp.status_code == 200){
        this.personaApiFail = false;
        this.currentContentPersona = resp.data;
        this.currentPlayingContentUrl = this.currentContentPersona.playback_url;
        if(this.playlistContent.length > 0){
          this.playlistContent = this.playlistContent.map(obj => {
            if(obj.content_slug == slug && obj.playbackurl === '') return {...obj, playbackurl:this.currentContentPersona.playback_url};
            return obj;
          });
          this.auidoPlayerTrigger();
        }else this.auidoPlayerTrigger();
      }
    }, (error) => this.personaApiFail = true)
  }

  auidoPlayerTrigger(){
    this.showPlayer = true;
    if(this.currentSelectedType == 'podcast') this.showPodcastMiniMobPlayer = true;
    this.showPodcastMiniMobPlayer = true;
    this.audioPlayer.nativeElement.src = this.currentPlayingContentUrl;
    this.audioPlayer.nativeElement.load();
    this.audioPlayer.nativeElement.play();
    this.isPlaying = true;
  }

  getAllContents(){
    const SubCatdata = {
      guest: this.guest,
      title: this.data.collection_slug,
      component: this.currentCollection.component,
      skip:0,
      take:100
    };
    this.subCategoryService.getSubCategory(SubCatdata).subscribe((data)=>{
      if(data){
        this.audioCollectionApiFail = false;
        data.contents.forEach((obj,index) =>{
          obj.playbackurl = '';
          obj.position = index;
        })
        this.playlistContent = [...data.contents];
        this.queueList = [...data.contents];
        if(this.position == this.playlistContent.length -1){
          if(this.audioRepeat){
            this.queueList = [...this.playlistContent].slice(0, this.position);
          }else this.queueList = [];
        }else {
          if(this.audioRepeat){
            let slicedArrayFirst = [...this.playlistContent].slice(this.position+1);
            let slicedArraySecond = [...this.playlistContent].slice(0,this.position+1);
            this.queueList =[...slicedArrayFirst, ...slicedArraySecond];
          }else this.queueList = [...this.playlistContent].slice(this.position+1);
        }
      }
    }, (error)=> this.audioCollectionApiFail =  true)
  }

  getAllEpisode(seriesSlug){
    this.categoriesService.getSeriesContents(seriesSlug).subscribe((seriesData) =>{
      if(seriesData){
        this.personaApiFail = false;
        seriesData?.seasons[0]?.contents.forEach((obj,index) =>{
          obj.playbackurl = '';
          obj.position = index;
        })
        this.episodesList = seriesData?.seasons[0]?.contents;
        this.playlistContent = [...this.episodesList];
        this.queueList = [...this.playlistContent];
        let currentSeries = seriesData?.series;
        if(this.genreCurationEnabled) this.currentContentAssetData.genre = currentSeries.genre_collections;
        else this.currentContentAssetData.genre = currentSeries.tags.split(",");
        this.currentContentAssetData.description = currentSeries?.description;
      }
    },(error)=> this.personaApiFail = true)
  }

  audioPlayEvent(index){
    let currentPlayingContent = this.playlistContent[this.position];
    let newPlayingSong = this.queueList[index];
    this.queueList.unshift(currentPlayingContent);
    if(this.audioRepeat) this.queueList = this.queueList.slice(index+2).concat(this.queueList.slice(0, index+1));
    else this.queueList = this.queueList.slice(index+2);
    this.currentContentAssetData.title = newPlayingSong.title;
    this.currentContentAssetData.cast = newPlayingSong.cast;
    this.currentContentAssetData.poster_square = newPlayingSong.poster_square;
    this.currentContentAssetData.is_audio = newPlayingSong.is_audio;
    this.currentContentAssetData.is_series = newPlayingSong.is_series;
    this.currentContentAssetData.content_id = newPlayingSong.content_id;
    this.position = this.playlistContent.findIndex((content) => {
      return content.content_slug === newPlayingSong.content_slug;
    });
    this.getContentPersona(newPlayingSong.content_slug);
  }

  episodePlayEvent(index){
    if(this.currentSelectedType == 'podcast' && this.windowWidth <= 1024) this.currentPlayingEpisodeIndex = index;
    if(!this.mobScreenEpisodePlayTrigger && this.windowWidth <= 1024) this.mobScreenEpisodePlayTrigger = true;
    const findObject = this.playlistContent.find(obj => obj.position == index);
    this.currentPlayingEpisode = {...findObject};
    if(this.windowWidth <= 1024) this.mobScreenCurrentEpisodeTitle = findObject.title;
    this.position = this.playlistContent.findIndex((content) => {
      return content.content_slug === findObject.content_slug;
    });
    this.personaApiCall(findObject.content_slug);
  }

  openUikitDialog(modelId){
    this.queueListOpen = true;
    $(modelId).addClass('uk-open').show();
    $('html').addClass('uk-modal-active-hidden');
  }

  closemodal(){
    if ($('html').hasClass('uk-modal-active-hidden')) {
      $('html').removeClass('uk-modal-active-hidden');
    }
    this.queueListOpen = false;
    if(!this.showPlayer) this.closePlayer();
  }

  audioSkipForward(seconds){
    this.audioPlayer.nativeElement.currentTime += seconds;
  }

  audioSkipBackward(seconds){
    this.audioPlayer.nativeElement.currentTime -= seconds;
  }

  updateCurrentProgressbar(){
    const currentTime = this.audioPlayer.nativeElement.currentTime;
    const duration = this.audioPlayer.nativeElement.duration;
  
    if (!isNaN(duration) && duration > 0) {
      this.currentProgressPercentage = (currentTime / duration) * 100;
      this.progressBar.nativeElement.style.width = `${this.currentProgressPercentage}%`;
    }
    this.updateCurrentTime(currentTime)
  }

  formatTime(seconds) {
    const minutes = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  }

  updateCurrentTime(currentTime, ) {
    this.currentPlayingTime = this.formatTime(currentTime);
  }

  updateTotalDuration(){
    const totalDuration = this.audioPlayer.nativeElement.duration;
    this.totalPlayingTime = this.formatTime(totalDuration);
  }

  playerPlay(){
    this.audioPlayer.nativeElement.play();
  }

  playerPause(){
    this.audioPlayer.nativeElement.pause();
  }

  onPlayerPlay(){
    this.isPlaying = true;
  }

  onPlayerPause(){
    this.isPlaying = false;
  }

  onAudioEnded(){
    this.isPlaying = false;
    if(this.queueList.length > 0) this.nextSong();
    this.currentPlayingTime = 0;
    this.totalPlayingTime = 0;
  }

  onAudioError(event){
    if(event.type == 'error'){

    }
  }

  expandMobieViewall(){
    this.mobileViewallExpand = true;
    this.mobileAudioPodcastContainer.nativeElement.scrollTo({ top: 0, behavior: 'smooth' });
  }

  shuffleEvent(){
    if(!this.shuffle){
      this.queueList = this.shuffleArray([...this.queueList]);
      this.shuffle = true;
    }
    else{
      if(!this.audioRepeat){
        if(this.position > 0){ 
          let slicedArrayFirst = [...this.playlistContent].slice(this.position+1);
          let slicedArraySecond = [...this.playlistContent].slice(0,this.position);
          this.queueList =[...slicedArrayFirst, ...slicedArraySecond];
        }else if(this.position == 0) this.queueList = [...this.playlistContent].slice(this.position+1);
      }else{
        if ((this.position == (this.playlistContent.length - 1))) this.queueList = [];
        else if((this.position >= 0) && (this.position != (this.playlistContent.length - 1))) this.queueList = [...this.playlistContent].slice(this.position+1);
      }
      this.shuffle = false;
    }
  }

  shuffleArray(array: any[]): any[] {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1)); 
      [array[i], array[j]] = [array[j], array[i]]; 
    }
    return array;
  }

  prevSong(){
    if(!this.audioRepeat && this.position == 0) return;
    this.audioPlayer.nativeElement.pause();
    this.isPlaying = false;
    this.audioPlayer.nativeElement.currentTime = 0;
    let playContent;
    let CurrentPlayingItem = this.playlistContent[this.position];
    if(this.audioRepeat){
      this.queueList.unshift(CurrentPlayingItem);
      playContent = this.queueList.pop();
    }else{
      if(this.position  > 0){
        this.queueList.unshift(CurrentPlayingItem);
        playContent = this.playlistContent[this.position - 1];
      }
    }
    this.currentContentAssetData.title = playContent.title;
    this.currentContentAssetData.cast = playContent.cast;
    this.currentContentAssetData.poster_square = playContent.poster_square;
    this.currentContentAssetData.is_audio = playContent.is_audio;
    this.currentContentAssetData.is_series = playContent.is_series;
    this.currentContentAssetData.content_id = playContent.content_id;
    this.position = this.playlistContent.findIndex((content) => {
      return content.content_slug === playContent.content_slug;
    });
    this.getContentPersona(playContent.content_slug);
  }

  nextSong(){
    if(!this.audioRepeat && (this.position == (this.playlistContent.length-1))) return;
    this.audioPlayer.nativeElement.pause();
    this.isPlaying = false;
    this.audioPlayer.nativeElement.currentTime = 0;
    let nextPlayItem = this.queueList.shift();
    let CurrentPlayingItem = this.playlistContent[this.position];
    if(this.audioRepeat) this.queueList.push(CurrentPlayingItem);
    this.currentContentAssetData.title = nextPlayItem.title;
    this.currentContentAssetData.cast = nextPlayItem.cast;
    this.currentContentAssetData.poster_square = nextPlayItem.poster_square;
    this.currentContentAssetData.is_audio = nextPlayItem.is_audio;
    this.currentContentAssetData.is_series = nextPlayItem.is_series;
    this.currentContentAssetData.content_id = nextPlayItem.content_id;
    this.position = this.playlistContent.findIndex((content) => {
      return content.content_slug === nextPlayItem.content_slug;
    });
    this.getContentPersona(nextPlayItem.content_slug);
  }

  repeat(){
    if(!this.audioRepeat){
      if(this.position > 0){ 
        let slicedArrayFirst = [...this.playlistContent].slice(this.position+1);
        let slicedArraySecond = [...this.playlistContent].slice(0,this.position);
        this.queueList =[...slicedArrayFirst, ...slicedArraySecond];
      }else if(this.position == 0) this.queueList = [...this.playlistContent].slice(this.position+1);
      this.audioRepeat = true;
    }else{
      if ((this.position == (this.playlistContent.length - 1))) this.queueList = [];
      else if((this.position >= 0) && (this.position != (this.playlistContent.length - 1))) this.queueList = [...this.playlistContent].slice(this.position+1);
      this.audioRepeat = false;
    }
  }

  changeChoosenTypeFunc(type){
    this.queuelistChoosen = type;
  }

  closePlayer(){
    if(this.queueListOpen) this.closemodal();
    this.currentContentCollectionSubscribe.unsubscribe();
    this.guestSubscribe.unsubscribe();
    this.selectedContentTypeSubscribe.unsubscribe();
    this.closeMiniPlayerSubscribe.unsubscribe();
    this.playlistContent = [];
    this.queueList = [];
    this.episodesList = [];
    this.playContentService.currentContentCollection.next({});
    this.playContentService.audioContentObject.next({});
    this.playContentService.showAudioPlayer.next(false);
    this.playContentService.selectedContentType.next('');
  }

}
