<div [class]="skinName">
  <div class="search-bg-img">
    @if(!audioSupported){
      <div class="search-page" [ngClass]="{'h-100': (searchCount==0 && totalGetCount == 0)}">
        <div class="search-count" [ngClass]="(searchCount==0 && totalGetCount == 0) ? 'height-100':'load-height'">
          @if (!overrideLoader) {
          <div [ngClass]="(searchCount==0 && totalGetCount == 0) ? 'no-result-content' : 'result-block triangle'">
            @if (totalGetCount) {
            <div>
              {{ configService.localeKeys?.['search.results_for.text']}} <span>&#8220;</span>{{searchName}}<span>&#8221;
                {{ configService.localeKeys?.['search.results_indicator_part3.text']}} </span>.
            </div>
            }
            @if ((searchCount==0 && totalGetCount == 0)) {
            <div>
              <div id="no-result">{{configService.localeKeys?.['error.no_results.text']}} <span>&#8220;</span>
                {{searchName}}<span>&#8221;</span>.</div>
            </div>
            }
          </div>
          }
          @if (overrideLoader) {
            <div class="loader">
              <img class="loader-size" src="branding/theme/assets/static_assets/ic-app-loader.svg" alt="">
            </div>
          }
        </div>
        @if (totalGetCount) {
        <div class="searchList">
          <app-card-list id="card-align" [searchTerm]="searchName" [purchases]="purchases" [isSearch]="true"
            [numCardsInRow]="numCardsInRow" [cards]="searchData" [searchComp]="true">
          </app-card-list>
          @if (paginate) {
          <div class="loader">
            <img class="loader-size" src="branding/theme/assets/static_assets/ic-app-loader.svg" alt="">
          </div>
          }
          <div class="card-clearfix"></div>
          <div class="open-more">
            @if (showMoreResult && !showLessButton) {
            <div id="open-trans">
              <span> {{configService.localeKeys?.['common_keys.more.btn']}} <img (click)="openMore()"
                  class="cursor-pointer" src="branding/theme/assets/static_assets/ic-down.svg" alt=""></span>
            </div>
            }
          </div>
        </div>
        }
        @if (searchOpenedFirst) {
        <div class="search-description">
          <img loading="lazy" class="search-zoom" src="branding/theme/assets/static_assets/ic-search-on-lbg.svg">
          <p>{{ configService.localeKeys?.['search.searching_guide.text']}}</p>
        </div>
        }
      </div>
    }
    @if(audioSupported){
      <div class="search-page" [ngClass]="{'h-100': (searchCount==0 && totalGetCount == 0 && totalAudioGetCount == 0)}">
        <div class="search-count" [ngClass]="(searchCount==0 && totalGetCount == 0 && totalAudioGetCount == 0) ? 'height-100':'load-height'">
          @if (!overrideLoader) {
          <div [ngClass]="(searchCount==0 && totalGetCount == 0 && totalAudioGetCount == 0) ? 'no-result-content' : 'result-block triangle'">
            @if (totalGetCount || totalAudioGetCount) {
            <div>
              {{ configService.localeKeys?.['search.results_for.text']}} <span>&#8220;</span>{{searchName}}<span>&#8221;
                {{ configService.localeKeys?.['search.results_indicator_part3.text']}} </span>.
            </div>
            }
            @if(totalGetCount && totalAudioGetCount){
             <div class="header_section text-left">
              <div class="tabs">
                @for (option of searchOptions; track option) {
                  <div class="tab" [ngClass]="{'tab-active': option == selectedTab }" (click)="selectTab(option)">{{option}}</div>
                }
              </div>
             </div>
            }
            @if ((searchCount==0 && totalGetCount == 0 && totalAudioGetCount == 0)) {
            <div>
              <div id="no-result">{{configService.localeKeys?.['error.no_results.text']}} <span>&#8220;</span>
                {{searchName}}<span>&#8221;</span>.</div>
            </div>
            }
          </div>
          }
          @if (overrideLoader) {
            <div class="loader">
              <img class="loader-size" src="branding/theme/assets/static_assets/ic-app-loader.svg" alt="">
            </div>
          }
        </div>
        @if ((totalGetCount && !totalAudioGetCount) || (totalGetCount && totalAudioGetCount && selectedTab =='Videos') ) {
        <div class="searchList">
          <app-card-list id="card-align" [searchTerm]="searchName" [purchases]="purchases" [isSearch]="true"
            [numCardsInRow]="numCardsInRow" [cards]="searchData" [searchComp]="true">
          </app-card-list>
          @if (paginate) {
          <div class="loader">
            <img class="loader-size" src="branding/theme/assets/static_assets/ic-app-loader.svg" alt="">
          </div>
          }
          <div class="card-clearfix"></div>
          <div class="open-more">
            @if (showMoreResult && !showLessButton) {
            <div id="open-trans">
              <span> {{configService.localeKeys?.['common_keys.more.btn']}} <img (click)="openMore()"
                  class="cursor-pointer" src="branding/theme/assets/static_assets/ic-down.svg" alt=""></span>
            </div>
            }
          </div>
        </div>
        }
        @if ((!totalGetCount && totalAudioGetCount) || (totalGetCount && totalAudioGetCount && selectedTab =='Audios')) {
          <div class="searchList">
            <app-card-list id="card-align" [searchTerm]="searchName" [purchases]="purchases" [isSearch]="true"
              [numCardsInRow]="numCardsInRow" [cards]="audioSearchData" [searchComp]="true">
            </app-card-list>
            @if (paginate) {
            <div class="loader">
              <img class="loader-size" src="branding/theme/assets/static_assets/ic-app-loader.svg" alt="">
            </div>
            }
            <div class="card-clearfix"></div>
            <div class="open-more">
              @if (showAudioMoreResult && !showAudioLessButton) {
              <div id="open-trans">
                <span> {{configService.localeKeys?.['common_keys.more.btn']}} <img (click)="openMoreAudio()"
                    class="cursor-pointer" src="branding/theme/assets/static_assets/ic-down.svg" alt=""></span>
              </div>
              }
            </div>
          </div>
          }
          @if(totalGetCount && totalAudioGetCount && selectedTab =='All'){
            <div class="slick-list">
              <div class="slide-area">
                @if (totalGetCount > 0) {
                <div class="post" >
                  <div class="box-title d-flex j-sb">
                    <div class="box-head row no-margin">
                      <h3 class="main-title">{{videoTitle}}</h3>
                    </div>
                    <div class="align-view-all">
                      <a class="see-all pull-right" (click)="selectTab('Videos')">
                        {{ configService.localeKeys?.['common_keys.view_all.btn']}}
                      </a>
                    </div>
                  </div>
                  <app-slick-item class="slick-item" [data]="searchSlickData"></app-slick-item>
                </div>
                }
                <div class="clearfix"></div>
              </div>
              <div class="slide-area">
              @if (totalAudioGetCount > 0) {
              <div class="post" >
                <div class="box-title d-flex j-sb">
                  <div class="box-head row no-margin">
                    <h3 class="main-title">{{audioTitle}}</h3>
                  </div>
                  <div class="align-view-all">
                    <a class="see-all pull-right" (click)="selectTab('Audios')">
                      {{ configService.localeKeys?.['common_keys.view_all.btn']}}
                    </a>
                  </div>
                </div>
                <app-slick-item class="slick-item" [data]="audioSlickData"></app-slick-item>
              </div>
              }
              <div class="clearfix"></div>
              </div>
            </div>
          }
        @if (searchOpenedFirst) {
        <div class="search-description">
          <img loading="lazy" class="search-zoom" src="branding/theme/assets/static_assets/ic-search-on-lbg.svg">
          <p>{{ configService.localeKeys?.['search.searching_guide.text']}}</p>
        </div>
        }
      </div>
    }
  </div>
</div>